/* eslint-disable react/jsx-pascal-case */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import Layout from "../../layout";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "./nftDetail.scss";
import NftDetail_Content from "./features/detailContent";
import { useEffect, useState } from "react";
import {
  useLazyGetNftByIdQuery,
  useLazyGetSimilarNftQuery,
  useLazyGetUsersListQuery,
  useLazyGetVendorsListQuery,
} from "../../services/main/setting";
import { NftResponse } from "../../types/General";
import { Loader } from "../../helpers";

const NftDetail = () => {
  const navigate = useNavigate();
  const { nftId } = useParams();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const type = params.get("type");
  const path = params.get("path");

  const [nfts, setNfts] = useState<NftResponse>();
  const [vendors, setVendors] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState<any>();
  const [similarNfts, setSimilarNfts] = useState<NftResponse[]>([]);
  const [couponShortNote, setCouponShortNote] = useState("");

  const [getNftByIdMethod] = useLazyGetNftByIdQuery();
  const [getVendorsMethod] = useLazyGetVendorsListQuery();
  const [getUsersListMethod] = useLazyGetUsersListQuery();
  const [getSimilarNftsMethod] = useLazyGetSimilarNftQuery();

  const getNftByIdData = async () => {
    setLoading(true);
    try {
      const result = await getNftByIdMethod({ nftId }).unwrap();
      setLoading(false);
      if (result?.statusCode === 200) {
        setNfts(result?.data || {});
        setCouponShortNote(result?.data?.coupon?.description || "");
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const getVendorsListData = async () => {
    try {
      const result = await getVendorsMethod({ nftId }).unwrap();
      if (result?.statusCode === 200) {
        setVendors(result?.data?.b2b || {});
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getUsersListData = async () => {
    try {
      const result = await getUsersListMethod({ nftId }).unwrap();
      if (result?.statusCode === 200) {
        setUsers(result?.data?.data || {});
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getSimilarNftsDetail = async () => {
    try {
      const result = await getSimilarNftsMethod({
        nftId: nftId || "",
        subCatId: nfts?.subCategoryId || "",
        fromAccount: type ? true : false,
      }).unwrap();
      if (result?.statusCode === 200) {
        setSimilarNfts(result?.data?.data || {});
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (nftId && nfts?.subCategoryId) {
      getSimilarNftsDetail();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nfts]);

  useEffect(() => {
    getNftByIdData();
    getVendorsListData();
    getUsersListData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nftId]);

  return (
    <Layout>
      <Loader isLoad={loading} />
      <main className="page_nftDetail">
        <section className="site_pagination uht_spc">
          <div className="conta_iner">
            <ul>
              <li>
                <a
                  // href="javascript:void(0)"
                  onClick={() => navigate("/", { replace: true })}
                >
                  Home
                </a>
              </li>
              {path === "platform" ? (
                <li>
                  <a
                    // href="javascript:void(0)"
                    onClick={() => {
                      if (window) {
                        window.history.back();
                      }
                    }}
                  >
                    Platform Nft's
                  </a>
                </li>
              ) : undefined}
              {path === "acc" ? (
                <li>
                  <a
                    // href="javascript:void(0)"
                    onClick={() => {
                      if (window) {
                        window.history.back();
                      }
                    }}
                  >
                    My Account
                  </a>
                </li>
              ) : undefined}
              {path === "cat" ? (
                <>
                  <li>
                    <a
                      // href="javascript:void(0)"
                      onClick={() =>
                        navigate(`/explore-subcategory/list`, { replace: true })
                      }
                    >
                      Explore
                    </a>
                  </li>
                  <li>
                    <a
                      // href="javascript:void(0)"
                      onClick={() => {
                        if (window) {
                          window.history.back();
                        }
                      }}
                    >
                      Category Detail
                    </a>
                  </li>
                </>
              ) : undefined}
              <li>Asset Detail</li>
            </ul>
          </div>
        </section>

        <NftDetail_Content
          setNfts={setNfts}
          nfts={nfts}
          type={type}
          getNftByIdData={getNftByIdData}
          vendors={vendors}
          setLoading={setLoading}
          users={users}
          similarNfts={similarNfts}
          setCouponShortNote={setCouponShortNote}
          couponShortNote={couponShortNote}
          path={path}
        />
      </main>
    </Layout>
  );
};

export default NftDetail;
