/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useState } from "react";
import "./marketplaceVendors.scss";
import Layout from "../../layout";
import MarketplaceVendors_Hero from "./features/hero";
import HomeVendors from "../landingPage/features/vendor";
import { useLocation } from "react-router-dom";

const MarketplaceVendors = () => {
  const location = useLocation();
  const { state } = location;

  return (
    <Layout>
      <main className="page_marketplaceVendors">
        <MarketplaceVendors_Hero type={state} />

        <section className="home_vendor_sc ut_spc">
          <div className="conta_iner">
            <div className="s_head text_center">
              <h2>
                {state === "partner" ? "Partners" : "Marketplace Vendors"}
              </h2>
              <p>
                {state === "partner"
                  ? `Impact2earn is supported by a pioneering group of partners united by a common goal of fostering sustainable living. For those interested in joining us in this collective pursuit, we encourage you to sign-up to become our partner.`
                  : `Impact2earn is supported by a pioneering group of vendors united by a common goal of fostering sustainable living. For those interested in joining us in this collective pursuit, we encourage you to sign-up to become our Vendor.`}
              </p>
            </div>
          </div>
        </section>

        <HomeVendors type={state} />
      </main>
    </Layout>
  );
};

export default MarketplaceVendors;
