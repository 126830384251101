/* eslint-disable no-script-url */
/* eslint-disable react/jsx-pascal-case */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import Layout from "../../layout";
import { useNavigate, useParams } from "react-router-dom";
import "./subcategoryDetail.scss";
import SubcategoryDetail_Content from "./features/detailContent";
import { useLazyGetNftsBySubCatQuery } from "../../services/main/category";
import { NftResponse } from "../../types/General";
import { Loader, STORAGE_KEYS, getFromStorage } from "../../helpers";

const SubcategoryDetail = () => {
  const navigate = useNavigate();
  const { subCatId } = useParams();

  const [nfts, setNfts] = useState<NftResponse[]>([]);
  const [value, setValue] = React.useState(0);
  const [subCategoryDetail, setSubCategoryDetail] = useState<any>();

  const [getNftByIdMethod, { isLoading }] = useLazyGetNftsBySubCatQuery();

  const getNftBySubCatIdData = async () => {
    try {
      const result = await getNftByIdMethod({
        subCatId,
        type: value + 1,
      }).unwrap();
      if (result?.statusCode === 200) {
        setNfts(result?.data?.data || []);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const subCat = getFromStorage(STORAGE_KEYS.subCategory);
    if (subCat) {
      setSubCategoryDetail(JSON.parse(subCat));
    }
  }, []);

  useEffect(() => {
    getNftBySubCatIdData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <Layout>
      <Loader isLoad={isLoading} />
      <main className="page_subcatDetail">
        <section className="site_pagination uht_spc">
          <div className="conta_iner">
            <ul>
              <li>
                <a href="javascript:void(0)" onClick={() => navigate("/")}>
                  Home
                </a>
              </li>
              <li>
                <a
                  // href="javascript:void(0)"
                  onClick={() =>
                    navigate(`/explore-subcategory/list`, { replace: true })
                  }
                >
                  Explore
                </a>
              </li>
              <li>Category Detail</li>
            </ul>
          </div>
        </section>

        <SubcategoryDetail_Content
          nfts={nfts}
          subCategoryDetail={subCategoryDetail}
          isLoading={isLoading}
          value={value}
          setValue={setValue}
        />
      </main>
    </Layout>
  );
};

export default SubcategoryDetail;
