import { END_POINTS } from "../helpers";
import { Blog, CommonBody, FaqData } from "../types/General";
import emptySplitApi from "../utils/rtk";

type CommonResponseType = {
  statusCode: number;
  message: string;
};

type BlogRes = {
  count: number;
  pages: number;
  data: Blog[];
};

export const cmsApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getCms: builder.query<CommonResponseType & { data: any }, {}>({
      query: () => ({
        url: END_POINTS.getCms,
        method: "GET",
      }),
    }),
    getFaqList: builder.query<
      CommonResponseType & {
        data: {
          data: FaqData[];
        };
      },
      {}
    >({
      query: () => ({
        url: END_POINTS.getFaq,
        method: "GET",
      }),
    }),
    //blog
    getAllBlog: builder.query<
      CommonResponseType & { data: BlogRes },
      { page?: number | undefined; size?: number | undefined }
    >({
      query: ({ page, size }) => ({
        url: `${END_POINTS.getBlog}?page=${page}&size=${size}`,
        method: "GET",
      }),
    }),
    getBlogById: builder.query<
      CommonResponseType & { data: Blog },
      { id: string }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.getBlog}/${id}`,
        method: "GET",
      }),
    }),
    //news
    getAllNews: builder.query<
      CommonResponseType & { data: BlogRes },
      { search: string; page: number }
    >({
      query: ({ search, page }) => ({
        url: `${END_POINTS.getNews}?search=${search}&page=${page}`,
        method: "GET",
      }),
    }),
    getNewsById: builder.query<
      CommonResponseType & { data: Blog },
      { id: string }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.getNews}/${id}`,
        method: "GET",
      }),
    }),
    //testimonial
    getAllTestimonial: builder.query<
      CommonResponseType & { data: BlogRes },
      {}
    >({
      query: () => ({
        url: END_POINTS.getTestimonial,
        method: "GET",
      }),
    }),
    getTestimonialById: builder.query<
      CommonResponseType & { data: Blog },
      { id: string }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.getTestimonial}/${id}`,
        method: "GET",
      }),
    }),

    getOrganizationList: builder.query<
      CommonResponseType & { data: any },
      {
        b2bType: number;
        orgId?: string | undefined;
        page: number;
        size: number;
        pagination: boolean;
        search?: string | undefined;
      }
    >({
      query: ({ b2bType, orgId, page, size, pagination, search }) => ({
        url: `${END_POINTS.getOrganisation}${
          orgId ? `/${orgId}` : ""
        }?b2bType=${b2bType}&page=${page}&size=${size}&pagination=${pagination}${
          search ? `&search=${search}` : ""
        }`,
        method: "GET",
      }),
    }),

    contactUsForm: builder.mutation<
      CommonResponseType & { data: any },
      CommonBody
    >({
      query: (body) => ({
        url: END_POINTS.support,
        method: "POST",
        body,
      }),
    }),
    newsletterForm: builder.mutation<
      CommonResponseType & { data: any },
      CommonBody
    >({
      query: (body) => ({
        url: END_POINTS.newsLetter,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useLazyGetCmsQuery,
  useLazyGetFaqListQuery,
  useLazyGetAllBlogQuery,
  useLazyGetBlogByIdQuery,
  useLazyGetAllNewsQuery,
  useLazyGetNewsByIdQuery,
  useLazyGetAllTestimonialQuery,
  useLazyGetTestimonialByIdQuery,
  useLazyGetOrganizationListQuery,
  useContactUsFormMutation,
  useNewsletterFormMutation,
} = cmsApi;
