import { END_POINTS } from "../../helpers";
import emptySplitApi from "../../utils/rtk";

type CommonResponseType = {
  statusCode: number;
  message: string;
};

export const homeApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getBanner: builder.query<CommonResponseType & { data: any }, {}>({
      query: () => ({
        url: END_POINTS.getBanners,
        method: "GET",
      }),
    }),
  }),
});

export const { useLazyGetBannerQuery } = homeApi;
