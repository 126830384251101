/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
import { useNavigate } from "react-router-dom";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { useLazyGetAllNewsQuery } from "../services/cms";
import { Blog } from "../types/General";
import { useEffect, useState } from "react";
import moment from "moment";

type newsProps = {
  search: string | any;
  page: number;
};

const NewsListing = ({ search, page }: newsProps) => {
  const navigate = useNavigate();

  const [getNews] = useLazyGetAllNewsQuery();

  const [news, setNews] = useState<Blog[]>([]);

  const getAllNews = async () => {
    try {
      const res = await getNews({ search: search, page: page }).unwrap();
      if (res?.statusCode === 200) {
        setNews(res?.data?.data || []);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllNews();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, search]);

  return (
    <>
      {news?.length ? (
        <div className="news_listing">
          <div className="gap_m hd_6">
            {news?.length
              ? news?.map((item) => (
                  <div
                    className="news_single"
                    onClick={() => navigate(`/news/detail/${item?._id}`)}
                  >
                    <figure>
                      <img src={item?.image || ""} alt="Image" />
                    </figure>
                    <div className="news_info">
                      <span className="date">
                        <strong>{moment(item?.createdAt).format("Do")}</strong>{" "}
                        {moment(item?.createdAt).format("MMM, YY")}
                      </span>
                      <span className="views">
                        <RemoveRedEyeIcon /> {item?.viewCount || 0} Views
                      </span>
                    </div>
                    <h3>{item?.title || ""}</h3>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: item?.description || "",
                      }}
                    ></p>
                    {item?.description?.length >= 100 ? (
                      <a 
                      // href="javascript:void(0)" 
                      className="btn_link">
                        Read more <KeyboardArrowRightIcon />
                      </a>
                    ) : null}
                  </div>
                ))
              : null}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default NewsListing;
