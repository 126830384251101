/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { AuthCommon } from '../../../../components';
import { useNavigate } from 'react-router-dom';
import {
    Button,
    CardMedia,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField,
    Input,
    Box,
    Modal,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useAppDispatch, useAppSelector } from '../../../../hooks/store';
import useAuth from '../../../../hooks/useAuth';
import { useEditProfileMutation } from '../../../../services/auth';
import {
    Loader,
    STORAGE_KEYS,
    errorToast,
    getFromStorage,
    setToStorage,
    successToast,
} from '../../../../helpers';
import { generateEncryptedKeyBody } from '../../../../utils/crypto';
import { CommonBody } from '../../../../types/General';
import {
    metaMaskAddress,
    setCredentials,
} from '../../../../reducers/authSlice';
import { UploadMedia } from '../../../../utils/mediaUpload';
import { isString } from '../../../../utils/validation';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';
import MetamaskModalContent from '../../../../components/metamastModal';
import { getCms } from '../../../../reducers/generalSlice';

declare global {
    interface Window {
        ethereum?: any; // This declares the ethereum object on the window
    }
}

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
};

const ProfileSetupContent = () => {
    const navigate = useNavigate();

    const user = useAuth();
    const dispatch = useAppDispatch();
    const cmsData = useAppSelector(getCms);
    const [updateProfile, { isLoading }] = useEditProfileMutation();
    const [socialAccount, setSocialAccount] = React.useState(
        user?.socialMediaType || ""
    );
    const [error, setError] = useState<boolean>(false);
    const [openModal, setModalOpen] = useState(false);

    const handleModalClose = () => setModalOpen(false);

    // const [addresses, setAddresses] = useState([
    //   { id: Math.random(), address: "" },
    // ]);
    // const [phoneNumbers, setPhoneNumbers] = useState([
    //   { id: Math.random(), countryCode: "+971", phoneNumber: "" },
    // ]);

    const connectWallet = async () => {
        if (window.ethereum) {
            // window.et console.log(user);hereum.on("chainChanged", (chainId: any) => {
            //   handleChainChanged(chainId);
            // });
            try {
                const accounts = await window.ethereum.request({
                    method: 'eth_requestAccounts',
                });
                if (accounts.length) {
                    setToStorage(STORAGE_KEYS.WalletAddress, accounts[0]);
                    dispatch(
                        metaMaskAddress({
                            walletAddress: accounts[0],
                        })
                    );
                    // await handleCheckUser(accounts[0]);
                }
            } catch (err) {
                console.log(err, 'kk');
            }
        } else {
            window.open('https://metamask.io/download/', '_blank');
        }
    };

    // const handleChangeAddress = (address: string, index: number) => {
    //   setAddresses((prevAddresses) => {
    //     const updatedAddresses = [...prevAddresses];
    //     updatedAddresses[index] = {
    //       ...prevAddresses[index],
    //       address: address,
    //     };
    //     return updatedAddresses;
    //   });
    // };

    // const handleRemoveAddress = (index: any) => {
    //   setAddresses((prevAddresses) => {
    //     const updatedAddresses = [...prevAddresses];
    //     updatedAddresses.splice(index, 1);
    //     formik.setFieldValue("addresses", updatedAddresses);
    //     return updatedAddresses;
    //   });
    // };

    // const handleAddAddress = () => {
    //   // setError(false);
    //   const newAddressEntry = {
    //     id: Math.random(),
    //     address: "",
    //   };
    //   setAddresses([...addresses, newAddressEntry]);
    // };

    const handleChange = (event: SelectChangeEvent) => {
        setSocialAccount(event.target.value as string);
    };

    //for image upload
    const handleImageUpload = async (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const files = event.target;
        const file = files?.files?.length ? files?.files[0] : '';
        if (file) {
            if (file.type.startsWith('image/')) {
                const res = await UploadMedia(file);
                if (res?.statusCode === 200) {
                    formik.setFieldValue('image', res?.data);
                } else {
                    errorToast(res?.message);
                }
            } else {
                errorToast('Please Try Again');
            }
        }
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            email: user?.email || '',
            name: user?.name || '',
            // bio: user?.bio || "",
            // socialId: user?.socialMediaId ? user.socialMediaId : undefined,
            image: user?.image || '',
            // dob: "",
        },
        validationSchema: Yup.object({
            email: Yup.string()
                .required('Email is required')
                .matches(
                    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
                    'Enter a valid email address'
                ),
            name: Yup.string()
                .required('First name is required')
                .min(2, 'Minimum 2 characters are required')
                .max(25, 'Maximum 25 character are allowed'),

            // socialId: Yup.string()
            //     .optional()
            //     .min(6, 'Social media id must be at least 6 characters')
            //     .matches(/^[^@]*$/, "Social media id cannot contain '@'"),
            // dob: Yup.string().required("This field is required"),
        }),
        onSubmit: async () => {
            formik.setSubmitting(true);
            setError(true);

            // const nonEmptyAddresses = addresses?.filter(
            //   (entry) => entry?.address?.trim()?.length > 0
            // );
            // const filterNumbers = phoneNumbers?.filter(
            //   (entry) => entry?.phoneNumber?.trim()?.length > 0
            // );

            // if (!filterNumbers?.length) {
            //   errorToast("Please enter Contact Number");
            //   return;
            // }

            // if (!nonEmptyAddresses?.length) {
            //   errorToast("Please enter Address");
            //   return;
            // }
            console.log(socialAccount);
            // if (socialAccount === "default") {
            //   return;
            // }
            setError(false);
            let body = {
                email: formik.values.email,
                name: formik.values.name,
                // bio: formik.values.bio,
                // socialMediaId: formik.values?.socialId,
                image: formik.values?.image || '',
                // socialMediaType: socialAccount,
                isProfileComplete: true,
                // dob: formik.values.dob || "",
                // contactNo: filterNumbers || [],
                // address: nonEmptyAddresses || [],
            } as any;

            // if (formik.values.dob) {
            //   body.dob = formik.values.dob;
            // }

            try {
                let encryptedBody = generateEncryptedKeyBody(
                    body
                ) as CommonBody;
                const response = await updateProfile(encryptedBody).unwrap();
                console.log(response);
                if (response?.statusCode === 200) {
                    const token = getFromStorage(STORAGE_KEYS.token);
                    successToast('Profile updated successfully');
                    dispatch(
                        setCredentials({
                            user: response?.data || null,
                            token: token || null,
                        })
                    );
                    navigate('/');
                } else {
                    errorToast(response?.message || '');
                }
            } catch (error: any) {
                errorToast(error?.data?.message || '');
            }
        },
    });

    // for country picker
    // @ts-ignore
    // const handleChangePhone = (phone, country, index) => {
    //   setPhoneNumbers((prevPhoneNumbers) => {
    //     const updatedPhoneNumbers = [...prevPhoneNumbers];
    //     const strippedPhoneNumber = phone.replace(country.dialCode, "");

    //     updatedPhoneNumbers[index] = {
    //       ...prevPhoneNumbers[index], // Preserve existing properties
    //       countryCode: country.dialCode?.includes("+")
    //         ? country.dialCode
    //         : "+" + country.dialCode,
    //       phoneNumber: strippedPhoneNumber,
    //     };

    //     return updatedPhoneNumbers;
    //   });
    // };

    // const handleRemovePhoneNumber = (index: any) => {
    //   setPhoneNumbers((prevPhoneNumbers) => {
    //     const updatedPhoneNumbers = [...prevPhoneNumbers];
    //     updatedPhoneNumbers.splice(index, 1);
    //     formik.setFieldValue("phoneNumbers", updatedPhoneNumbers); // Update formik state
    //     return updatedPhoneNumbers;
    //   });
    // };

    // const handleAddPhoneNumber = () => {
    //   // setError(false);
    //   const newPhoneNumberEntry = {
    //     id: Math.random(), // Generate a unique ID
    //     countryCode: "+971", // Set the default country code or get it from somewhere else
    //     phoneNumber: "",
    //   };
    //   setPhoneNumbers([...phoneNumbers, newPhoneNumberEntry]);
    // };

    return (
        <>
            <Loader isLoad={isLoading} />
            <section className="auth_section">
                <div className="conta_iner">
                    <div className="gap_p">
                        <AuthCommon />
                        <div className="right_side">
                            <form
                                onSubmit={formik.handleSubmit}
                                action=""
                                className="form hd_3"
                            >
                                <h2>Profile Setup</h2>
                                <div className="form_control">
                                    <div className="profile_flex">
                                        {formik.values.image ? (
                                            <div className="upload_image">
                                                <div className="upload_image_holder">
                                                    <figure>
                                                        <CardMedia
                                                            component="img"
                                                            image={
                                                                formik.values
                                                                    .image
                                                            }
                                                            alt="photo"
                                                        />
                                                    </figure>
                                                    <CloseIcon
                                                        onClick={() => {
                                                            formik.setFieldValue(
                                                                'image',
                                                                ''
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        ) : (
                                            <label
                                                className="upload_image"
                                                htmlFor="icon-button-file"
                                            >
                                                <Input
                                                    sx={{ display: 'none' }}
                                                    id="icon-button-file"
                                                    type="file"
                                                    inputProps={{
                                                        accept: '.png, .jpg, .jpeg, .svg',
                                                    }}
                                                    onChange={handleImageUpload}
                                                />
                                                <span className="upload_image_holder">
                                                    <figure>
                                                        <img
                                                            src="/images/placeholder_user_icon.png"
                                                            alt=""
                                                        />
                                                    </figure>
                                                    <AddIcon />
                                                </span>
                                            </label>
                                        )}
                                        {cmsData?.isWeb3Enabled ? (
                                            <button
                                                type="button"
                                                className="btn btn_white wallet_btn "
                                                onClick={() =>
                                                    setModalOpen(true)
                                                }
                                            >
                                                <img
                                                    src="/images/wallet_icon.png"
                                                    alt="Wallet Icon"
                                                />{' '}
                                                {user?.walletAddress
                                                    ? `${user?.walletAddress?.slice(
                                                          0,
                                                          5
                                                      )}...${user?.walletAddress?.slice(
                                                          -5
                                                      )}`
                                                    : 'Connect to Wallet'}
                                            </button>
                                        ) : undefined}
                                    </div>
                                </div>
                                <div className="form_control">
                                    <label>Name</label>
                                    <TextField
                                        hiddenLabel
                                        placeholder="Name"
                                        fullWidth
                                        type="text"
                                        variant="outlined"
                                        name="name"
                                        className="text_field"
                                        id="name"
                                        onChange={(val) => {
                                            if (
                                                val.target.value === ' ' ||
                                                val.target.value === '.'
                                            ) {
                                            } else if (
                                                isString(val.target.value)
                                            ) {
                                                formik.handleChange(val);
                                            }
                                        }}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.name}
                                        helperText={
                                            formik.touched.name &&
                                            formik.errors.name
                                        }
                                    />
                                </div>
                                <div className="form_control">
                                    <label>Email Address</label>
                                    <TextField
                                        className="text_field"
                                        hiddenLabel
                                        placeholder="Email"
                                        disabled
                                        fullWidth
                                        type="email"
                                        variant="outlined"
                                        name="email"
                                        id="email"
                                        onChange={(val) => {
                                            if (
                                                val.target.value === ' ' ||
                                                val.target.value === '.'
                                            ) {
                                            } else {
                                                formik.handleChange(val);
                                            }
                                        }}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.email}
                                        helperText={
                                            formik.touched.email &&
                                            formik.errors.email
                                        }
                                    />
                                </div>
                                {/* <div className="form_control">
                  <label>Bio</label>
                  <TextField
                    hiddenLabel
                    placeholder="Bio"
                    fullWidth
                    type="text"
                    variant="outlined"
                    name="bio"
                    className="text_field"
                    id="bio"
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else if (isString(val.target.value)) {
                        formik.handleChange(val);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.bio}
                    helperText={formik.touched.bio && formik.errors.bio}
                  />
                </div> */}
                                {/* <div className="form_control" style={{display: 'none'}}>
                                    <label>Social Media Account</label>
                                    <Select
                                        fullWidth
                                        labelId="demo-multiple-name-label"
                                        id="demo-multiple-name"
                                        label="social-account"
                                        placeholder='Select Account'
                                        value={
                                            socialAccount || ''    
                                        }
                                        onChange={handleChange}
                                    >
                                        <MenuItem value={""}>
                                            Select Account
                                        </MenuItem>
                                        <MenuItem value={1}>Facebook</MenuItem>
                                        <MenuItem value={2}>Telegram</MenuItem>
                                        <MenuItem value={3}>Twitter</MenuItem>
                                        <MenuItem value={4}>LinkedIn</MenuItem>
                                        <MenuItem value={5}>WhatsApp</MenuItem>
                                    </Select>
                                    {error && socialAccount === 'default' ? (
                                        <h6
                                            className="err_msg"
                                            style={{ paddingTop: 10 }}
                                        >
                                            This field is required
                                        </h6>
                                    ) : (
                                        ''
                                    )}
                                </div>
                                <div className="form_control" style={{display: 'none'}}>
                                    <label>User Name</label>
                                    <TextField
                                        hiddenLabel
                                        placeholder="User Name"
                                        fullWidth
                                        type="text"
                                        variant="outlined"
                                        name="socialId"
                                        className="text_field"
                                        id="socialId"
                                        onChange={(val) => {
                                            if (
                                                val.target.value === ' ' ||
                                                val.target.value === '.' ||
                                                val.target.value?.includes('@')
                                            ) {
                                            } else {
                                                formik.handleChange(val);
                                            }
                                        }}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.socialId}
                                        helperText={
                                            formik.touched.socialId &&
                                            formik.errors.socialId
                                        }
                                    />
                                </div> */}
                                {/* <div className="form_control">
                  <label>Date of Birth (optional)</label>
                  <TextField
                    hiddenLabel
                    placeholder="Date of Birth"
                    fullWidth
                    type="date"
                    variant="outlined"
                    className="text_field"
                    name="dob"
                    id="dob"
                    value={formik.values.dob}
                    onChange={formik.handleChange} */}
                                {/* helperText={
                       formik.touched.dob && formik.errors.dob
                     }
                  /> */}
                                {/* {Boolean(formik.touched.dob && formik.errors.dob) ? (
                    <span className="helper-text">
                      <h6 className="err_msg">
                        {(formik.touched.dob && formik.errors.dob) as any}
                      </h6>
                    </span>
                  ) : undefined} */}
                                {/* </div> */}
                                {/* <div className="form_control">
                  {phoneNumbers.map((entry, index) => (
                    <div key={entry.id} className="form_control">
                      <label>
                        Contact Number{" "}
                        {phoneNumbers?.length > 1 ? index + 1 : ""}
                      </label>
                      <div className="p_relative">
                        <PhoneInput
                          value={entry.countryCode + entry.phoneNumber}
                          country={entry.countryCode}
                          placeholder="Contact Number"
                          enableSearch={true}
                          inputStyle={{ width: "100%" }}
                          onChange={(phone, country) =>
                            handleChangePhone(phone, country, index)
                          }
                          onBlur={formik.handleBlur}
                        />
                      </div>
                      {error &&
                      !entry?.phoneNumber?.length &&
                      phoneNumbers?.length < 2 ? (
                        <span className="helper-text">
                          <h6 className="err_msg">Phone number is required</h6>
                        </span>
                      ) : undefined}
                      {error &&
                      entry?.phoneNumber?.length &&
                      entry?.phoneNumber?.length < 7 ? (
                        <span className="helper-text">
                          <h6 className="err_msg">
                            Phone number must have at least 7 digits.
                          </h6>
                        </span>
                      ) : undefined}
                      {phoneNumbers?.length > 1 &&
                      entry?.phoneNumber?.length ? (
                        <div className="text_end">
                          <button
                            type="button"
                            className="btn_link sm"
                            onClick={() => handleRemovePhoneNumber(index)}
                          >
                            REMOVE
                          </button>
                        </div>
                      ) : undefined}
                    </div>
                  ))}
                  <div className="text_end">
                    <a
                      // href="javascript:void(0)"
                      className="btn_link sm"
                      onClick={handleAddPhoneNumber}
                    >
                      ADD ANOTHER CONTACT NUMBER
                    </a>
                  </div>
                </div> */}
                                {/* <div className="form_control">
                  {addresses.map((entry, index) => (
                    <div key={entry.id} className="form_control">
                      <label>
                        Address (optional){" "}
                        {addresses?.length > 1 ? index + 1 : ""}
                      </label>
                      <div className="p_relative">
                        <TextField
                          hiddenLabel
                          placeholder="Address"
                          fullWidth
                          type="text"
                          variant="outlined"
                          name={`addresses[${index}].address`}
                          className="text_field"
                          onChange={(val) =>
                            handleChangeAddress(val.target.value, index)
                          }
                          onBlur={formik.handleBlur}
                          value={entry.address}
                        />
                      </div>
                      {addresses?.length > 1 && entry?.address && (
                        <div className="text_end">
                          <button
                            type="button"
                            className="btn_link sm"
                            onClick={() => handleRemoveAddress(index)}
                          >
                            REMOVE
                          </button>
                        </div>
                      )} */}
                                {/* {error &&
                      !entry.address?.length &&
                      addresses?.length < 2 ? (
                        <span className="helper-text">
                          <h6 className="err_msg">Address is required</h6>
                        </span>
                      ) : undefined} */}
                                {/* </div>
                  ))}
                  <div className="text_end">
                    <a
                      // href="javascript:void(0)"
                      className="btn_link sm"
                      onClick={handleAddAddress}
                    >
                      ADD ANOTHER ADDRESS
                    </a>
                  </div>
                </div> */}
                                <div className="form_btn">
                                    <Button
                                        className="btn btn_primary"
                                        color="primary"
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        onClick={() => setError(true)}
                                    >
                                        Submit
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <Modal
                    open={openModal}
                    onClose={handleModalClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style} className="modal_body">
                        <CloseIcon
                            onClick={handleModalClose}
                            className="close"
                        />
                        <MetamaskModalContent />
                    </Box>
                </Modal>
            </section>
        </>
    );
};

export default ProfileSetupContent;
