import { memo } from "react";
import { useNavigate } from "react-router-dom";

const AuthCommon = ({ type }: { type?: string }) => {
  const navigate = useNavigate();

  return (
    <>
      <div className="left_side">
        <h1>
          Sign up for our platform and{" "}
          <span className="c_primary">contribute to a greener planet</span>
        </h1>
        <div className="btn_flex">
          <button
            className="btn btn_lg btn_primary"
            onClick={() => navigate("/platform-nfts")}
          >
            Explore
          </button>
          {type === "login" ? (
            <button
              className="btn btn_lg btn_primary"
              onClick={() => navigate("/signup")}
            >
              Sign Up
            </button>
          ) : undefined}
        </div>
      </div>
    </>
  );
};

export default memo(AuthCommon);
