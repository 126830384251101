import { END_POINTS } from "../../helpers";
import { CommonBody, MyWallet } from "../../types/General";
import emptySplitApi from "../../utils/rtk";

type CommonResponseType = {
  statusCode: number;
  message: string;
};

export const settingApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getDbNft: builder.query<
      CommonResponseType & { data: any },
      {
        page: number;
        size: number;
        sort?: number | undefined | string;
        categoryId?: string | undefined;
        sortType?: string | undefined;
        type: number;
      }
    >({
      query: ({ page, size, sort, sortType, categoryId, type }) => ({
        url: `${END_POINTS.dbNft}?type=${type}&page=${page}&size=${size}${
          sort ? `&sort=${sort}` : ""
        }${sortType ? `&sortType=${sortType}` : ""}${
          categoryId ? `&categoryId=${categoryId}` : ""
        }`,
        method: "GET",
      }),
    }),

    convertToPassive: builder.mutation<
      { statusCode: number; data: any; message: string },
      {
        body: CommonBody;
        nftId: string;
      }
    >({
      query: ({ body, nftId }) => ({
        url: `${END_POINTS.convertToPassive}/${nftId}`,
        method: "PUT",
        body,
      }),
    }),

    getPassiveNft: builder.query<
      CommonResponseType & { data: any },
      {
        page: number;
        size: number;
        type: number;
        sort?: number | undefined | string;
        categoryId?: string | undefined;
        sortType?: string | undefined;
      }
    >({
      query: ({ page, size, type, sort, sortType, categoryId }) => ({
        url: `${END_POINTS.passiveNft}?type=${type}&page=${page}&size=${size}${
          sort ? `&sort=${sort}` : ""
        }${sortType ? `&sortType=${sortType}` : ""}${
          categoryId ? `&categoryId=${categoryId}` : ""
        }`,
        method: "GET",
      }),
    }),

    getNftById: builder.query<
      CommonResponseType & { data: any },
      { nftId: any }
    >({
      query: ({ nftId }) => ({
        url: `${END_POINTS.nftById}/${nftId}`,
        method: "GET",
      }),
    }),

    setOnBid: builder.mutation<
      { statusCode: number; data: any; message: string },
      {
        body: CommonBody;
        nftId: string;
      }
    >({
      query: ({ body, nftId }) => ({
        url: `${END_POINTS.setOnBid}/${nftId}`,
        method: "PUT",
        body,
      }),
    }),

    getMyNftOnBid: builder.query<
      CommonResponseType & { data: any },
      {
        page: number;
        size: number;
        sort?: number | undefined | string;
        categoryId?: string | undefined;
        sortType?: string | undefined;
        type: number;
      }
    >({
      query: ({ page, size, sort, sortType, categoryId, type }) => ({
        url: `${END_POINTS.myNftOnBid}?type=${type}&page=${page}&size=${size}${
          sort ? `&sort=${sort}` : ""
        }${sortType ? `&sortType=${sortType}` : ""}${
          categoryId ? `&categoryId=${categoryId}` : ""
        }`,
        method: "GET",
      }),
    }),

    handleBidWinner: builder.mutation<
      { statusCode: number; data: any; message: string },
      {
        body: CommonBody;
        nftId: string;
      }
    >({
      query: ({ body, nftId }) => ({
        url: `${END_POINTS.acceptBidWinner}/${nftId}`,
        method: "POST",
        body,
      }),
    }),

    sendGiftNft: builder.mutation<
      { statusCode: number; data: any; message: string },
      {
        body: CommonBody;
        nftId: string;
      }
    >({
      query: ({ body, nftId }) => ({
        url: `${END_POINTS.giftNft}/${nftId}`,
        method: "POST",
        body,
      }),
    }),

    getVendorsList: builder.query<CommonResponseType & { data: any }, {}>({
      query: () => ({
        url: `${END_POINTS.getVendorsList}`,
        method: "GET",
      }),
    }),

    claimCoupon: builder.mutation<
      { statusCode: number; data: any; message: string },
      {
        body: CommonBody;
        couponId: string;
      }
    >({
      query: ({ body, couponId }) => ({
        url: `${END_POINTS.claimCoupon}/${couponId}`,
        method: "POST",
        body,
      }),
    }),

    getUsersList: builder.query<CommonResponseType & { data: any }, {}>({
      query: () => ({
        url: `${END_POINTS.getUsers}`,
        method: "GET",
      }),
    }),

    getMyGiftedNfts: builder.query<
      CommonResponseType & { data: any },
      {
        page: number;
        size: number;
        sort?: number | undefined | string;
        categoryId?: string | undefined;
        sortType?: string | undefined;
      }
    >({
      query: ({ page, size, sort, sortType, categoryId }) => ({
        url: `${END_POINTS.myGiftNft}?page=${page}&size=${size}${
          sort ? `&sort=${sort}` : ""
        }${sortType ? `&sortType=${sortType}` : ""}${
          categoryId ? `&categoryId=${categoryId}` : ""
        }`,
        method: "GET",
      }),
    }),

    getMyClaimedCoupons: builder.query<
      CommonResponseType & { data: any },
      { page: number; size: number }
    >({
      query: ({ page, size }) => ({
        url: `${END_POINTS.myClaimedCoupon}?page=${page}&size=${size}`,
        method: "GET",
      }),
    }),

    getSimilarNft: builder.query<
      CommonResponseType & { data: any },
      { nftId: string; subCatId: string; fromAccount: boolean }
    >({
      query: ({ nftId, subCatId, fromAccount }) => ({
        url: `${END_POINTS.getSimilarNfts}/${subCatId}?nftId=${nftId}&fromAccount=${fromAccount}`,
        method: "GET",
      }),
    }),

    getMyActivities: builder.query<
      CommonResponseType & { data: any },
      { page: number; size: number }
    >({
      query: ({ page, size }) => ({
        url: `${END_POINTS.activities}?page=${page}&size=${size}`,
        method: "GET",
      }),
    }),

    clearAllActivities: builder.query<CommonResponseType & { data: any }, {}>({
      query: () => ({
        url: `${END_POINTS.activities}?clearAll=true`,
        method: "DELETE",
      }),
    }),

    updateCoupon: builder.mutation<
      { statusCode: number; data: any; message: string },
      {
        body: CommonBody;
        claimCouponId: string;
      }
    >({
      query: ({ body, claimCouponId }) => ({
        url: `${END_POINTS.updateCoupon}/${claimCouponId}`,
        method: "PUT",
        body,
      }),
    }),

    getSocialMediaList: builder.query<CommonResponseType & { data: any }, {}>({
      query: () => ({
        url: `${END_POINTS.socialMedia}`,
        method: "GET",
      }),
    }),

    addSocialMedia: builder.mutation<
      { statusCode: number; data: any; message: string },
      CommonBody
    >({
      query: (body) => ({
        url: END_POINTS.socialMedia,
        method: "POST",
        body,
      }),
    }),

    updateSocialMedia: builder.mutation<
      { statusCode: number; data: any; message: string },
      {
        body: CommonBody;
        socialMediaId: string;
      }
    >({
      query: ({ body, socialMediaId }) => ({
        url: `${END_POINTS.socialMedia}/${socialMediaId}`,
        method: "PUT",
        body,
      }),
    }),

    getMyWallet: builder.query<CommonResponseType & { data: MyWallet }, {}>({
      query: () => ({
        url: `${END_POINTS.myWallet}`,
        method: "GET",
      }),
    }),

    getOrganizationCoupon: builder.mutation<
    { statusCode: number; data: any; message: string },
    {
      body: CommonBody;
    }
  >({
    query: ({ body }) => ({
      url: `${END_POINTS.getOrganizationCoupon}`,
      method: "POST",
      body,
    }),
  }),
  }),
});

export const {
  useLazyGetDbNftQuery,
  useConvertToPassiveMutation,
  useLazyGetPassiveNftQuery,
  useLazyGetNftByIdQuery,
  useSetOnBidMutation,
  useLazyGetMyNftOnBidQuery,
  useHandleBidWinnerMutation,
  useSendGiftNftMutation,
  useLazyGetVendorsListQuery,
  useClaimCouponMutation,
  useLazyGetUsersListQuery,
  useLazyGetMyGiftedNftsQuery,
  useLazyGetMyClaimedCouponsQuery,
  useLazyGetSimilarNftQuery,
  useLazyGetMyActivitiesQuery,
  useUpdateCouponMutation,
  useLazyGetSocialMediaListQuery,
  useAddSocialMediaMutation,
  useUpdateSocialMediaMutation,
  useLazyGetMyWalletQuery,
  useGetOrganizationCouponMutation,
  useLazyClearAllActivitiesQuery,
} = settingApi;
