import { useNavigate } from "react-router-dom";

const CorporateSitesTrusted = () => {
    const navigate = useNavigate();

    return (
        <section className="Corp_trusted_sc u_spc" style={{ backgroundImage: "url(" + "/images/attachment_06.jpg" + ")" }}>
            <div className="conta_iner">
                <div className="s_head text_white text_center">
                    <h2>We are trusted by</h2>
                </div>
                <figure><img src="/images/partner_logo.png" alt="Logos" /></figure>
            </div>
        </section>
    );
};

export default CorporateSitesTrusted;
