/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { Dispatch, SetStateAction } from "react";
import { useNavigate } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import { NftResponse } from "../../../types/General";
import moment from "moment";
import NoDataFound from "../../../components/noData";
import useAuth from "../../../hooks/useAuth";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const SubcategoryDetail_Content = ({
  nfts,
  subCategoryDetail,
  isLoading,
  value,
  setValue,
}: {
  nfts: NftResponse[] | undefined;
  subCategoryDetail: any;
  isLoading: boolean;
  value: number;
  setValue: Dispatch<SetStateAction<number>>;
}) => {
  const navigate = useNavigate();
  const user = useAuth();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <section className="categoryDetail_sc ub_spc">
      <figure>
        <img
          src={subCategoryDetail?.coverImage || "/images/attachment_01.jpg"}
          alt="Image"
        />
      </figure>

      <div className="conta_iner">
        <div className="cat_content">
          <figure className="cat_content_figure">
            <img
              src={
                subCategoryDetail?.image || "/images/subcat_attachment_1.jpg"
              }
              alt="Image"
            />
          </figure>
          <div className="hd_3">
            <h1>{subCategoryDetail?.name || ""}</h1>
            {/* <p>By RACrane</p> */}
          </div>
          <ul className="list_datail">
            {/* <li>Unique items 7</li> */}
            <li>Total items {nfts?.length || 0}</li>
            <li>{`Created ${moment(subCategoryDetail?.createdAt).format(
              "MMM YYYY"
            )}`}</li>
            {/* <li>Chain Ethereum</li> */}
            {/* <li>{`Category ${subCategoryDetail?.categoryId?.name || ""}`}</li> */}
          </ul>
          <p>{subCategoryDetail?.description || ""}</p>
          <ul className="boxed_detail">
            <li>
              <h2>{nfts?.length || 0}</h2>
              <p>Listed</p>
            </li>
            {/* <li>
              <h2>26%</h2>
              <p>Unique Owners</p>
            </li> */}
          </ul>
        </div>
        <div className="custom_tabs">
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="basic tabs example"
            className="custom_tabs_links"
          >
            <Tab label="Passive" {...a11yProps(0)} />
            <Tab
              // className="clickDisable"
              // disabled
              label="Minted"
              {...a11yProps(1)}
            />
          </Tabs>
          <CustomTabPanel value={value} index={0}>
            <div className="subcategory_listing">
              <div className="gap_m">
                {nfts?.length ? (
                  nfts?.map((item: NftResponse) => (
                    <div
                      className={`subcategory_listing_single ${
                        item?.couponClaimed ? "claimed" : ""
                      }`}
                      onClick={() => {
                        if (user?._id === item?.userId) {
                          navigate(`/nft-detail/${item?._id}?type=1&path=cat`);
                        } else {
                          navigate(`/nft-detail/${item?._id}?path=cat`);
                        }
                      }}
                      // onClick={() =>
                      //   navigate(`/nft-detail/${item?._id}?path=cat`)
                      // }
                      key={item?._id}
                    >
                      <figure>
                        <img
                          src={item?.image || "/images/nft_attachment_1.jpg"}
                          alt="Image"
                        />
                      </figure>
                      <h3>{item?.name || "Nokia"}</h3>
                      {/* <p>By remnynt</p> */}
                      <span className="tag c_primary">
                        {item?.sellType === 1 ? "On Bid" : "Private"}
                      </span>
                    </div>
                  ))
                ) : isLoading ? undefined : (
                  <NoDataFound
                    image={"/images/noData.png"}
                    text="No Data Found"
                  />
                )}
              </div>
            </div>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <div className="subcategory_listing">
              <div className="gap_m">
                {nfts?.length ? (
                  nfts?.map((item: NftResponse) => (
                    <div
                      className={`subcategory_listing_single ${
                        item?.couponClaimed ? "claimed" : ""
                      }`}
                      onClick={() => {
                        if (user?._id === item?.userId) {
                          navigate(`/nft-detail/${item?._id}?type=1&path=cat`);
                        } else {
                          navigate(`/nft-detail/${item?._id}?path=cat`);
                        }
                      }}
                      // onClick={() =>
                      //   navigate(`/nft-detail/${item?._id}?path=cat`)
                      // }
                      key={item?._id}
                    >
                      <figure>
                        <img
                          src={item?.image || "/images/nft_attachment_1.jpg"}
                          alt="Image"
                        />
                      </figure>
                      <h3>{item?.name || "Nokia"}</h3>
                      {/* <p>By remnynt</p> */}
                      <span className="tag c_primary">
                        {item?.sellType === 1 ? "On Bid" : "Private"}
                      </span>
                    </div>
                  ))
                ) : isLoading ? undefined : (
                  <NoDataFound
                    image={"/images/noData.png"}
                    text="No Data Found"
                  />
                )}
              </div>
            </div>
          </CustomTabPanel>
        </div>
      </div>
    </section>
  );
};

export default SubcategoryDetail_Content;
