/* eslint-disable react/jsx-pascal-case */
import React from "react";
import Layout from "../../layout";
import JobOpportunities_Form from "./features/form";

const JobOpportunities = () => {
    return (
        <Layout>
            <main className="page_jobOpportunities">
                <JobOpportunities_Form />
            </main>
        </Layout>
    );
};

export default JobOpportunities;
