/* eslint-disable jsx-a11y/img-redundant-alt */
import { useNavigate } from "react-router-dom";

const HomeLearn = () => {
    const navigate = useNavigate();

    return (
        <section className="home_learn_sc ub_spc">
            <div className="conta_iner">
                <div className="gap_p aic">
                    <div className="left_s s_hd">
                        <p>LEARN HOW WE ACTIVELY REWARD SUSTAINABLE RECYCLING BEHAVIORS FOR CONSUMERS & BUSINESSES</p>
                        <h2>Rewarding sustainability, one deposit at a time.</h2>
                        <button className="btn btn_lg btn_primary" onClick={() => navigate("/learn-how")}>Learn how</button>
                    </div>
                    <div className="right_s">
                        <figure><img src="/images/learn_attachment.jpg" alt="Image" /></figure>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default HomeLearn;
