/* eslint-disable react/jsx-pascal-case */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import Layout from "../../layout";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "./vendorDetail.scss";
import VendorDetail_Content from "./features/detailContent";
import { useLazyGetOrganizationListQuery } from "../../services/cms";
import { useEffect, useState } from "react";
import { Organizations } from "../../types/General";

const VendorDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  console.log(state, "STATE");
  const { id } = useParams();

  const [getVendorsMethod] = useLazyGetOrganizationListQuery();
  const [selectedId, setSelectedId] = useState(id || "");

  const [vendorsData, setVendorsData] = useState<Organizations>();

  const getAllVendorsData = async () => {
    try {
      const res = await getVendorsMethod({
        b2bType: state === "partner" ? 2 : 1,
        orgId: selectedId,
        size: 3,
        page: 1,
        pagination: false,
      }).unwrap();
      if (res?.statusCode === 200) {
        setVendorsData(res?.data || undefined);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllVendorsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedId]);

  return (
    <Layout>
      <main className="page_explore">
        <section className="site_pagination uht_spc">
          <div className="conta_iner">
            <ul>
              <li>
                <a href="javascript:void(0)" onClick={() => navigate("/")}>
                  Home
                </a>
              </li>
              <li>
                {state === "partner" ? (
                  <a
                    // href="javascript:void(0)"
                    onClick={() =>
                      navigate("/marketplace-vendors", { state: "partner" })
                    }
                  >
                    Partner
                  </a>
                ) : (
                  <a
                    // href="javascript:void(0)"
                    onClick={() =>
                      navigate("/marketplace-vendors", { state: "vendor" })
                    }
                  >
                    Vendor
                  </a>
                )}
              </li>
              <li>
                {state === "partner" ? "Partner Detail" : "Vendor Detail"}
              </li>
            </ul>
          </div>
        </section>

        <VendorDetail_Content
          vendorsData={vendorsData}
          setSelectedId={setSelectedId}
          type={state}
        />
      </main>
    </Layout>
  );
};

export default VendorDetail;
