import Layout from "../../../layout";
import "../../auth/auth.scss";
import ResetPasswordContent from "./features/resetPassword";

const ResetPassword = () => {
  return (
    <Layout>
      <main>
        <ResetPasswordContent />
      </main>
    </Layout>
  );
};

export default ResetPassword;
