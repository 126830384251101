/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import { Dispatch, SetStateAction } from "react";
import { useNavigate } from "react-router-dom";
import SiteFilter from "../../../components/filter";
import { useLazyGetMyGiftedNftsQuery } from "../../../services/main/setting";
import { Loader } from "../../../helpers";
import SitePagination from "../../../components/sitePagination";
import { NftResponse } from "../../../types/General";
import NoDataFound from "../../../components/noData";

const GiftsReceived = ({
  steps,
  setSteps,
}: {
  steps: number;
  setSteps: Dispatch<SetStateAction<number>>;
}) => {
  const navigate = useNavigate();
  const [isListView, setIsListView] = useState(false);

  const [nfts, setNfts] = useState<any>([]);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  const [sortSelect, setSortSelect] = useState("default");
  const [positionSelect, setPositionSelect] = useState("1");
  const [categorySelect, setCategorySelect] = useState("All");
  const [isLoading, setIsLoading] = useState(false);
  let totalPages = Math.ceil(totalCount / 12);

  console.log(positionSelect, ">>>>>>>");

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const [getMyGiftsNftMethod] = useLazyGetMyGiftedNftsQuery();

  const getDataBaseNft = async () => {
    setIsLoading(true);
    try {
      const result = await getMyGiftsNftMethod({
        page: page,
        size: 12,
        sort: sortSelect !== "default" ? sortSelect : "",
        sortType:
          sortSelect !== "default"
            ? // eslint-disable-next-line eqeqeq
              positionSelect == "1"
              ? "asc"
              : "desc"
            : "",
        categoryId:
          categorySelect !== "default" && categorySelect !== "All"
            ? categorySelect
            : "",
      }).unwrap();
      if (result?.statusCode === 200) {
        setNfts(result?.data?.data || []);
        setTotalCount(result?.data?.count || 0);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getDataBaseNft();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, positionSelect, sortSelect, categorySelect]);

  return (
    <>
      <Loader isLoad={isLoading} />
      <h3>Gifts Received</h3>
      <div className="custom_tabs ">
        <SiteFilter
          setCategorySelect={setCategorySelect}
          categorySelect={categorySelect}
          setPositionSelect={setPositionSelect}
          sortSelect={sortSelect}
          setSortSelect={setSortSelect}
          positionSelect={positionSelect}
          setIsListView={setIsListView}
          isListView={isListView}
        />
        <div
          className={
            !isListView
              ? "subcategory_listing"
              : "subcategory_listing list_view"
          }
        >
          <div className="gap_m">
            {nfts?.length
              ? nfts?.map((item: NftResponse) => (
                  <div
                    className={`subcategory_listing_single ${
                      item?.couponClaimed ? "claimed" : ""
                    }`}
                    onClick={() =>
                      navigate(`/nft-detail/${item?._id}?type=1&path=acc`)
                    }
                  >
                    <figure>
                      <img
                        src={item?.image || "/images/explore_attachment_1.jpg"}
                        alt="Image"
                      />
                    </figure>
                    <h3>{item?.name || ""}</h3>
                    <p>{item?.subCategory?.name || ""}</p>
                  </div>
                ))
              : undefined}
          </div>
          {nfts?.length ? (
            <SitePagination
              module={nfts}
              page={page}
              onPageChange={onPageChange}
              totalPages={totalPages}
              setPage={setPage}
            />
          ) : undefined}
          {/* <div className="btn_flex">
            <button className="btn btn_primary">View More</button>
          </div> */}
        </div>
      </div>
      {!isLoading && !nfts?.length ? (
        <NoDataFound image={"/images/noData.png"} text="No Data Found" />
      ) : undefined}
    </>
  );
};

export default GiftsReceived;
