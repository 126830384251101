/* eslint-disable no-useless-concat */
/* eslint-disable jsx-a11y/img-redundant-alt */
import { useNavigate } from "react-router-dom";
import {
  becomePartnerLink,
  becomeVendorLink,
} from "../../../helpers/constants/urls";

type homeProps = {
  type: string;
};

const MarketplaceVendors_Hero = ({ type }: homeProps) => {
  console.log(type, "DFDD");
  const navigate = useNavigate();

  return (
    <section
      className="mpVendors_hero uh_spc"
      style={{
        backgroundImage:
          type === "partner"
            ? "url(" + "/images/mpPartners_hero_attachment.jpg" + ")"
            : "url(" + "/images/mpVendors_hero_attachment.jpeg" + ")",
      }}
    >
      <div className="conta_iner">
        <div className="m_hd text_white">
          {type === "partner" ? (
            <h1>Partners</h1>
          ) : (
            <h1>Marketplace Vendors</h1>
          )}

          <p>Success is best when it’s shared.</p>

          <button
            className="btn btn_lg btn_primary"
            // onClick={() => navigate("/become-a-vendor")}
            onClick={() => {
              if (type === "partner") {
                window.open(becomePartnerLink, "_self");
              } else {
                window.open(becomeVendorLink, "_self");
              }
            }}
          >
            {type === "partner" ? "Become a Partner" : "Become a Vendor"}
          </button>
        </div>
      </div>
    </section>
  );
};

export default MarketplaceVendors_Hero;
