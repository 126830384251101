import Layout from "../../../layout";
import "../../auth/auth.scss";
import OtpContent from "./features/otp";

const Otp = () => {
  return (
    <Layout>
      <main>
        <OtpContent />
      </main>
    </Layout>
  );
};

export default Otp;
