import { useNavigate } from "react-router-dom";

const CorporateSitesHero = () => {
    const navigate = useNavigate();

    return (
        <section className="Corp_hero uh_spc" style={{ backgroundImage: "url(" + "/images/attachment_04.jpg" + ")" }}>
            <div className="conta_iner">
                <div className="s_hd text_white">
                    <h1>Paving the Future of Recycling with AI and Blockchain</h1>
                    <p>Give e-waste. Get rewards. We make recycling not just a conscientious choice but an engaging and rewarding habit.</p>
                    <button className="btn btn_lg btn_white">Get Your Rewards</button>
                </div>
            </div>
        </section>
    );
};

export default CorporateSitesHero;
