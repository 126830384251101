import React, { useEffect, useState } from 'react';
import './home.scss';
import Layout from '../../layout';
import HomeHero from './features/banner';
import HomeSteps from './features/steps';
import HomeLearn from './features/learn';
import HomeExplore from './features/explore';
import HomePartner from './features/partner';
import HomeVendors from './features/vendor';
import HomeTestimonial from './features/testimonial';
import HomeBlogs from './features/blogs';
import JoinMovement from './features/join';
import HomeNews from './features/news';
import { useNavigate } from 'react-router-dom';
import userAuth from '../../hooks/useAuth';
import YourHomeExplore from './features/yourNFTExplore';
import { useLazyGetPassiveNftQuery } from '../../services/main/setting';

const LandingPage = () => {
    const navigate = useNavigate();
    const [getAllNftsMethod] = useLazyGetPassiveNftQuery();
    const [nfts, setNfts] = useState<any>([]);
    const user = userAuth();
    const getAllNftsData = async () => {
     
      try {
          const result = await getAllNftsMethod({
              page: 1,
              size: 10,
              type: 1,
              // categoryId: 'All'
              // search: "",
          }).unwrap();
          if (result?.statusCode === 200) {
              setNfts(result?.data?.data || []);
              
          }
         
      } catch (error) {
         
          console.log(error);
      }
  };

    useEffect(() => {
      getAllNftsData();
  }, []);

    return (
        <Layout>
            <main className="page_home">
                <HomeHero />
                <HomeSteps />
                {user && nfts.length > 0 && <YourHomeExplore />}
                <HomeLearn />
                <HomeExplore />
                <HomePartner type="" />

                <HomeVendors type="" />

                <HomeTestimonial />
                <HomeBlogs />
                <JoinMovement />
                <HomeNews />
            </main>
        </Layout>
    );
};

export default LandingPage;
