/* eslint-disable jsx-a11y/img-redundant-alt */
import { useNavigate } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";

const JoinMovement = () => {
  const navigate = useNavigate();
  const user = useAuth();

  return (
    <section className="joinMovement_sc bg_primary u_spc">
      <div className="conta_iner">
        <div className="s_head text_center text_white">
          <p>
            Are you ready{" "}
            <img src="/images/target_icon.svg" alt="Target Icon" />
          </p>
          <h2>Join the New Movement</h2>
        </div>
        <div className="btn_flex">
          {user ? undefined : (
            <button
              onClick={() => navigate("/signup-for-free")}
              className="btn btn_lg btn_white br"
            >
              Sign up For Free
            </button>
          )}
          <button
            onClick={() => {
              if (window) {
                window.open("https://t.me/impact_to_earn", "_blank");
              }
            }}
            className="btn btn_lg btn_white br"
          >
            Join our Telegram Group
          </button>
        </div>
      </div>
    </section>
  );
};

export default JoinMovement;
