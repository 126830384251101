/* eslint-disable jsx-a11y/img-redundant-alt */
import * as React from "react";
import { styled } from "@mui/material/styles";
import AddIcon from "@mui/icons-material/Add";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { FaqData } from "../../../types/General";
import { useLazyGetFaqListQuery } from "../../../services/cms";
import { Loader } from "../../../helpers";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<AddIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({}));

const FAQContent = () => {
  const [getFaqMethod] = useLazyGetFaqListQuery();

  const [faqs, setFaqs] = React.useState<FaqData[]>([]);
  const [loading, setLoading] = React.useState(false);

  const getFaqListing = async () => {
    try {
      setLoading(true);
      const res = await getFaqMethod({}).unwrap();
      setLoading(false);
      if (res?.statusCode === 200) {
        setFaqs(res?.data?.data || []);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  React.useEffect(() => {
    getFaqListing();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className="faq_sc uh_spc">
      <Loader isLoad={loading} />
      <div className="conta_iner">
        <div className="s_head text_center">
          <h1>Frequent Asked Questions</h1>
        </div>
        {faqs?.length ? (
          faqs?.map((item) => (
            <Accordion
              key={item?.id}
              //   expanded={expanded === "panel1"}
              //   onChange={handleChange("panel1")}
            >
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
              >
                <p> {item?.question || ""}</p>
              </AccordionSummary>
              <AccordionDetails>
                <p>{item?.answer || ""}</p>
              </AccordionDetails>
            </Accordion>
          ))
        ) : !loading ? (
          <p>No faq's listing found</p>
        ) : undefined}
      </div>
    </section>
  );
};

export default FAQContent;
