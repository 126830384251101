/* eslint-disable jsx-a11y/img-redundant-alt */
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import { useLazyGetAllNftsQuery } from "../../../services/main/category";
import { useEffect, useState } from "react";
import { NftResponse } from "../../../types/General";
import useAuth from "../../../hooks/useAuth";

const HomeExplore = () => {
  const navigate = useNavigate();
  const user = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [nfts, setNfts] = useState<any>([]);
  const [swiperReady, setSwiperReady] = useState(false);

  const [getAllNftsMethod] = useLazyGetAllNftsQuery();

  const getAllNftsData = async () => {
    setIsLoading(true);
    try {
      const result = await getAllNftsMethod({
        page: 1,
        size: 10,
        type: 1,
        search: "",
      }).unwrap();
      if (result?.statusCode === 200) {
        setNfts(result?.data?.data || []);
        setSwiperReady(true);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getAllNftsData();
  }, []);

  return (
    <section className="home_explore_sc bg_secondary u_spc">
      <div className="conta_iner">
        <div className="gap_p aic">
          <div className="left_s text_white s_hd">
            <h2>Latest Deposits</h2>
            <p>
              See what our users have recycled, and how many impact points and carbon credits were earned for each deposit.
            </p>

            <button
              className="btn btn_primary"
              onClick={() => navigate("/platform-nfts")}
            >
              Explore
            </button>
          </div>
          <div className="right_s">
            {swiperReady && (
              <Swiper
                spaceBetween={20}
                slidesPerView={3}
                autoplay={{
                  delay: 4000,
                  disableOnInteraction: false,
                }}
                modules={[Autoplay, Navigation]}
                centeredSlides
                loop={true}
                navigation
                breakpoints={{
                  300: {
                    slidesPerView: 1,
                    spaceBetween: 15,
                    centeredSlides: false,
                  },
                  576: {
                    slidesPerView: 2,
                    centeredSlides: false,
                  },
                  768: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                    centeredSlides: false,
                  },
                  992: {
                    slidesPerView: 3,
                    centeredSlides: true,
                  },
                }}
                className="explore_swiper inline_arrow"
              >
                {nfts.map((item: NftResponse) => (
                  <SwiperSlide key={item?._id}>
                    <div
                      className="expore_box_single"
                      onClick={() => navigate(`/nft-detail/${item?._id}`)}
                    >
                      <figure>
                        <img
                          src={
                            item?.image || "/images/explore_attachment_1.jpg"
                          }
                          alt="Image"
                        />
                      </figure>
                      <h3>{item?.name || ""}</h3>
                      <p>{item?.categoryId?.name || ""}</p>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeExplore;
