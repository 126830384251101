/* eslint-disable jsx-a11y/img-redundant-alt */

import { CommonBody, NftResponse } from "../../../types/General";
import { useBidOnNftMutation } from "../../../services/main/category";
import { generateEncryptedKeyBody } from "../../../utils/crypto";
import {
  Loader,
  STORAGE_KEYS,
  errorToast,
  getFromStorage,
  successToast,
} from "../../../helpers";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../../hooks/store";
import { getToken } from "../../../reducers/authSlice";
import moment from "moment";
import Web3 from "web3";
import {
  CommonMarketABI,
  CommonMarketAddress,
} from "../../../utils/configuration";
import useAuth from "../../../hooks/useAuth";
import { getCms } from "../../../reducers/generalSlice";

const OnBid_Detail = ({
  nfts,
  getNftByIdData,
}: {
  nfts: NftResponse | undefined;
  getNftByIdData: () => void;
}) => {
  const token = useAppSelector(getToken);
  const user = useAuth();
  const [loading, setLoading] = useState(false);
  const [isMetaMaskConnected, setIsMetaMaskConnected] = useState(false);
  const [metaMaskOwner, setMetaMaskOwner] = useState("");
  const [copied, setCopied] = useState(false);
  const cmsData = useAppSelector(getCms);

  const [setBidMethod] = useBidOnNftMutation();

  const web3 = new Web3(window.ethereum);

  const CommonMarketContract = new web3.eth.Contract(
    CommonMarketABI,
    CommonMarketAddress
  );

  const handleBid = async () => {
    let data = {
      amount: nfts?.basePrice || 0,
      bid: nfts?.isBid ? "remove" : "add",
    };

    try {
      setLoading(true);
      const body = generateEncryptedKeyBody(data) as CommonBody;
      const result = await setBidMethod({
        body,
        nftId: nfts?._id || "",
      }).unwrap();
      setLoading(false);
      if (result?.statusCode === 200) {
        successToast(
          nfts?.isBid ? "Bid removed successfully" : "Bided successfully"
        );
        getNftByIdData();
      }
    } catch (error: any) {
      setLoading(false);
      console.log(error, ">>>");
      errorToast(error?.data?.message || "");
    }
  };

  const bidSetHandle = async () => {
    const id = nfts?.uniqueNftId ? Number(nfts?.uniqueNftId) : "";
    const accounts = await web3.eth.getAccounts();
    const bidAmount = nfts?.basePrice || 0;
    if (nfts?.nftType === 2 && nfts?.isBid) {
      try {
        setLoading(true);
        let gas = await CommonMarketContract.methods
          .withdrawBid(id)
          .estimateGas({ from: accounts[0] });

        await CommonMarketContract.methods
          .withdrawBid(id)
          .send({ from: accounts[0], gas: String(gas) })
          .on("transactionHash", function (sHash) {
            console.log(sHash, "sHashhhhhhhh");
          })
          .on("receipt", function (receipt) {
            setLoading(false);
            console.log(receipt, "receipt>>>>>>>");
            // @ts-ignore
            if (receipt?.status) {
              handleBid();
            }
          });
      } catch (error: any) {
        setLoading(false);
        if (error?.data?.message || error?.message) {
          errorToast(error?.data?.message || error?.message);
        }
        console.log(error, "llllll");
      }
    } else if (nfts?.nftType === 2) {
      try {
        setLoading(true);
        let gas = await CommonMarketContract.methods
          .bidNft(id)
          .estimateGas({ from: accounts[0] });

        console.log(bidAmount, accounts[0], id, "sdfgdsgfd");

        await CommonMarketContract.methods
          .bidNft(id)
          .send({
            from: accounts[0],
            // value: web3.utils.toWei(bidAmount, "wei"),
            gas: String(gas),
          })
          .on("transactionHash", function (sHash) {
            console.log(sHash, "sHashhhhhhhh");
          })
          .on("receipt", function (receipt) {
            setLoading(false);
            console.log(receipt, "receipt>>>>>>>");
            // @ts-ignore
            if (receipt?.status) {
              handleBid();
            }
          });
      } catch (error: any) {
        setLoading(false);
        if (error?.data?.message || error?.message) {
          errorToast(error?.data?.message || error?.message);
        }
        console.log(error, "llllll");
      }
    }
  };

  const handleBidCheck = async () => {
    const token = getFromStorage(STORAGE_KEYS.token);
    if (window.ethereum) {
      if (window.ethereum.isConnected()) {
        try {
          const accounts = await window.ethereum.request({
            method: "eth_requestAccounts",
          });
          if (accounts?.length) {
            if (token && accounts[0] !== user?.walletAddress) {
              errorToast("Please connect to a valid wallet address");
            } else {
              bidSetHandle();
            }
          }
        } catch (err) {
          console.log(err, "kk");
        }
      } else {
        errorToast("You need to connect to the metamask to proceed further");
        console.log("Metamask is not connected.");
      }
    } else {
      console.log("Metamask is not installed.");
    }
  };

  const getNftDetails = async () => {
    let id = nfts?.uniqueNftId ? Number(nfts?.uniqueNftId) : "";
    try {
      let sResult = (await CommonMarketContract.methods
        .getAssetDetails(id)
        .call()) as any;
      console.log(sResult, "fsfjksdhfjshdfj");
      setMetaMaskOwner(sResult?.owner || "");

      return sResult;
    } catch (error: any) {
      setMetaMaskOwner("");
      // if (error?.data?.message || error?.message) {
      //   errorToast(error?.data?.message || error?.message);
      // }
      console.log(error);
    }
  };

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(metaMaskOwner);
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 3000);
    } catch (error) {
      console.error("Error copying to clipboard:", error);
    }
  };

  useEffect(() => {
    if (isMetaMaskConnected && nfts?.nftType === 2) {
      getNftDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMetaMaskConnected, nfts]);

  useEffect(() => {
    if (window.ethereum) {
      window.ethereum
        .request({ method: "eth_accounts" })
        .then((accounts: string | any[]) => {
          if (accounts.length > 0) {
            setIsMetaMaskConnected(true);
          } else {
            setIsMetaMaskConnected(false);
          }
        })
        .catch((error: any) => {
          console.error(error);
          setIsMetaMaskConnected(false);
        });
    } else {
      setIsMetaMaskConnected(false);
    }
  }, []);

  return (
    <>
      <p className="links">
        {`Owned by: ${
          `${nfts?.ownerName || "Admin"} ${
            metaMaskOwner
              ? `(${
                  metaMaskOwner?.slice(0, 7) + "..." + metaMaskOwner?.slice(-6)
                })`
              : ""
          }` || "Admin"
        }`}{" "}
        {metaMaskOwner ? (
          <>
            <figure title="Copy">
              <img
                onClick={copyToClipboard}
                src="/images/copy_icon.svg"
                alt="Icon"
              />
              {copied && (
                <span style={{ color: "green" }}>Copied to clipboard!</span>
              )}
            </figure>
          </>
        ) : undefined}
      </p>
      {/* <p className="links">{`Owned by: ${
        metaMaskOwner || nfts?.ownerName || "Admin"
      }`}</p> */}
      <Loader isLoad={loading} />
      <>
        {nfts?.sellType === 1 ? (
          <div className="hd_4">
            <p>I2E Points:</p>
            <h3>{nfts?.basePrice || 0}</h3>
          </div>
        ) : // <div className="hd_5">
        //   <h3>
        //     I2E Points{" "}
        //     <span className="c_primary">{nfts?.basePrice || 0}</span>
        //   </h3>
        // </div>
        undefined}
        {cmsData?.isWeb3Enabled && token && nfts?.sellType === 1 ? (
          <div className="btn_flex">
            <button
              onClick={() => {
                if (nfts?.nftType === 1) {
                  handleBid();
                } else {
                  handleBidCheck();
                }
              }}
              className="btn btn_primary"
            >
              {nfts?.isBid ? "Remove your Bid" : "Bid Now"}
            </button>
          </div>
        ) : undefined}
      </>
      {nfts?.bidding?.length ? (
        <div className="detail_box v2">
          <h4>OFFERS</h4>
          <div className="table_responsive">
            <table>
              <thead>
                <tr>
                  <th>By</th>
                  <th>Amount</th>
                  <th>Date</th>
                </tr>
              </thead>
              {nfts?.bidding?.map((item) => (
                <tbody key={item?._id}>
                  <tr>
                    <td>{item?.bidderName || ""}</td>
                    <td>{item?.amount || 0}</td>
                    <td>{moment(item?.createdAt).format("D MMM YYYY")}</td>
                  </tr>
                </tbody>
              ))}
            </table>
          </div>
        </div>
      ) : undefined}
    </>
  );
};

export default OnBid_Detail;
