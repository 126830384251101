import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { FormControl, MenuItem, Select } from "@mui/material";
import { Dispatch, SetStateAction } from "react";

type props = {
  module: any;
  page: number;
  onPageChange: (newPage: number) => void;
  totalPages: number;
  setPage: Dispatch<SetStateAction<number>>;
};

const SitePagination = ({
  module,
  page,
  onPageChange,
  totalPages,
  setPage,
}: props) => {
  return (
    <div className="pagination">
      {/* <p>
        <span>Showing {module?.length ? `${module?.length}` : 0} items </span>
      </p> */}
      <div className="pages">
        {totalPages > 5 ? (
          <FormControl fullWidth>
            <Select
              className="select_pgntn"
              style={{ width: 60 }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              inputProps={{
                "aria-label": "Without label",
              }}
              displayEmpty
              value={page}
              onChange={(pages: any) => setPage(pages.target.value)}
            >
              <MenuItem value="" disabled>
                Select
              </MenuItem>
              {Array.from({ length: totalPages }).map((_, index) => (
                <MenuItem key={index + 1} value={index + 1}>
                  {index + 1}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : null}

        <div
          className="prevbtn"
          onClick={() => {
            if (page === 1) {
            } else {
              onPageChange(page - 1);
            }
          }}
          //   disabled={page === 1}
        >
          <ArrowBackIosNewIcon />
        </div>
        <div className="count">
          {totalPages > 6
            ? Array.from({ length: totalPages })
                .slice(0, 5)
                .map((_, index) => (
                  <div
                    className={page === index + 1 ? "actv" : "inActv"}
                    key={index}
                    onClick={() => {
                      if (page === index + 1) {
                      } else {
                        onPageChange(index + 1);
                      }
                    }}
                    // disabled={page === index + 1}
                    // type="button"
                  >
                    {index + 1}
                  </div>
                ))
                .concat(
                  <span
                    key="ellipsis"
                    className="ellipsis"
                    style={{ color: "black" }}
                  >
                    ...
                  </span>,
                  <div
                    className="actv"
                    // type="button"
                    style={{
                      display:
                        page < 6 || page === totalPages ? "none" : undefined,
                    }}
                    key={totalPages}
                  >
                    {page}
                  </div>,
                  <div
                    className={page === totalPages ? "actv" : "inActv"}
                    key={totalPages}
                    // type="button"
                    onClick={() => onPageChange(totalPages)}
                    // disabled={page === totalPages}
                  >
                    {totalPages}
                  </div>
                )
            : Array.from({ length: totalPages }).map((_, index) => (
                <div
                  className={page === index + 1 ? "actv" : "inActv"}
                  //   type="button"
                  key={index}
                  onClick={() => {
                    if (page === index + 1) {
                    } else {
                      onPageChange(index + 1);
                    }
                  }}
                  //   disabled={page === index + 1}
                >
                  {index + 1}
                </div>
              ))}
        </div>
        <div
          className="prevbtn"
          onClick={() => {
            if (page === totalPages) {
            } else {
              onPageChange(page + 1);
            }
          }}
          //   disabled={page === totalPages}
        >
          <ArrowForwardIosIcon />
        </div>
      </div>
    </div>
  );
};

export default SitePagination;
