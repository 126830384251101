/* eslint-disable react/jsx-pascal-case */
import React from "react";
import "./learnHow.scss";
import Layout from "../../layout";
import LearnHow_Hero from "./features/hero";
import JoinMovement from "../landingPage/features/join";
import LearnHow_Process from "./features/process";
import LearnHow_Video from "./features/video";

const LearnHow = () => {
    return (
        <Layout>
            <main className="page_learnHow">
                <LearnHow_Hero />
                <LearnHow_Process />
                <LearnHow_Video />
                <JoinMovement />
            </main>
        </Layout>
    );
};

export default LearnHow;
