/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useEffect } from "react";
import { Dispatch, SetStateAction } from "react";
import {
  Button,
  CardMedia,
  TextField,
  Input,
  Modal,
  Box,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import MetamaskModalContent from "../../../components/metamastModal";
import { useAppDispatch, useAppSelector } from "../../../hooks/store";
import { useEditProfileMutation } from "../../../services/auth";
import * as Yup from "yup";
import { useFormik } from "formik";
import { generateEncryptedKeyBody } from "../../../utils/crypto";
import { CommonBody } from "../../../types/General";
import {
  STORAGE_KEYS,
  errorToast,
  getFromStorage,
  successToast,
} from "../../../helpers";
import { setCredentials } from "../../../reducers/authSlice";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { UploadMedia } from "../../../utils/mediaUpload";
import { isString } from "../../../utils/validation";
import useAuth from "../../../hooks/useAuth";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Wallet from "./wallet";
import MyActivities from "./myActivities";
import ChangePassword from "./changePassword";
import ManageSocialMedia from "./manageSocialMedia";
import AddSocialMedia from "./addSocialMedia";
import { getCms } from "../../../reducers/generalSlice";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};

declare global {
  interface Window {
    ethereum?: any; // This declares the ethereum object on the window
  }
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Setting = ({
  steps,
  setSteps,
  setSocialMediaHandles,
  setSocialMedia,
  socialMedia,
  setSelectedMedia,
  getSocialMediaData,
  socialMediaHandles,
  selectedMedia,
}: {
  steps: number;
  setSteps: Dispatch<SetStateAction<number>>;
  setSocialMediaHandles: Dispatch<SetStateAction<number>>;
  setSocialMedia: any;
  socialMedia: any;
  setSelectedMedia: Dispatch<SetStateAction<any>>;
  getSocialMediaData: () => void;
  socialMediaHandles: any;
  selectedMedia: any;
}) => {
  const user = useAuth();
  const dispatch = useAppDispatch();
  const cmsData = useAppSelector(getCms);

  const [updateProfile] = useEditProfileMutation();

  const [error, setError] = useState<boolean>(false);
  const [openModal, setModalOpen] = useState(false);
  const [walletAddress, setWalletAddress] = useState<string | null>("");
  const [addresses, setAddresses] = useState([
    { id: Math.random(), address: "" },
  ]);
  const [phoneNumbers, setPhoneNumbers] = useState([
    { id: Math.random(), countryCode: "+971", phoneNumber: "" },
  ]);

  console.log(phoneNumbers);
  

  const handleChangeAddress = (address: string, index: number) => {
    setAddresses((prevAddresses) => {
      const updatedAddresses = [...prevAddresses];
      updatedAddresses[index] = {
        ...prevAddresses[index],
        address: address,
      };
      return updatedAddresses;
    });
  };

  const handleRemoveAddress = (index: any) => {
    setAddresses((prevAddresses) => {
      const updatedAddresses = [...prevAddresses];
      updatedAddresses.splice(index, 1);
      formik.setFieldValue("addresses", updatedAddresses);
      return updatedAddresses;
    });
  };

  const handleAddAddress = () => {
    const newAddressEntry = {
      id: Math.random(),
      address: "",
    };
    setAddresses([...addresses, newAddressEntry]);
  };

  useEffect(() => {
    if (user) {
      setPhoneNumbers(user?.contactNo?.length ? user?.contactNo  : [{ id: Math.random(), countryCode: "+971", phoneNumber: "" }]);
      setAddresses(
        user?.address?.length
          ? user?.address
          : [{ id: Math.random(), address: "" }]
      );
    }
  }, [user]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: user?.email || "",
      name: user?.name || "",
      address: "",
      image: user?.image || "",
      phone: user?.phone || "",
      countryCode: user?.countryCode || "+971",
      dob: user?.dob
        ? new Date(
            new Date(user?.dob).setDate(new Date(user?.dob).getDate() + 16)
          )
            .toISOString()
            .split("T")[0]
        : "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required("Email is required")
        .matches(
          /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
          "Enter a valid email address"
        ),
      name: Yup.string()
        .required("First name is required")
        .min(2, "Minimum 2 characters are required")
        .max(25, "Maximum 25 character are allowed"),
      // dob: Yup.string().required("This field is required"),
    }),
    onSubmit: async (values: any, { resetForm }) => {
      formik.setSubmitting(true);
      setError(true);

      const nonEmptyAddresses = addresses?.filter(
        (entry) => entry?.address?.trim()?.length > 0
      );
      const filterNumbers = phoneNumbers?.filter(
        (entry) => entry?.phoneNumber?.trim()?.length > 0
      );

      if (!filterNumbers?.length) {
        errorToast("Please enter Contact Number");
        return;
      }

      // if (!nonEmptyAddresses?.length) {
      //   errorToast("Please enter Address");
      //   return;
      // }
      setError(false);
      let body = {
        email: formik.values.email,
        name: formik.values.name,
        image: formik.values?.image || "",
        contactNo: filterNumbers || [],
        address: nonEmptyAddresses || [],
      } as any;

      if (formik.values.dob) {
        body.dob = formik.values.dob;
      }
      if (walletAddress) {
        body.walletAddress = walletAddress;
      }

      console.log(body);

      try {
        let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
        const response = await updateProfile(encryptedBody).unwrap();
        if (response?.statusCode === 200) {
          const token = getFromStorage(STORAGE_KEYS.token);
          successToast("Profile updated successfully");
          dispatch(
            setCredentials({
              user: response?.data || null,
              token: token || null,
            })
          );
        } else {
          errorToast(response?.message || "");
        }
      } catch (error: any) {
        errorToast(error?.data?.message || "");
      }
    },
  });

  const handleModalClose = () => setModalOpen(false);

  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target;
    const file = files?.files?.length ? files?.files[0] : "";
    if (file) {
      if (file.type.startsWith("image/")) {
        const res = await UploadMedia(file);
        if (res?.statusCode === 200) {
          formik.setFieldValue("image", res?.data);
        } else {
          errorToast(res?.message);
        }
      } else {
        errorToast("Please Try Again");
      }
    }
  };

  // @ts-ignore
  const handleChangePhone = (phone, country, index) => {
    setPhoneNumbers((prevPhoneNumbers) => {
      const updatedPhoneNumbers = [...prevPhoneNumbers];
      const strippedPhoneNumber = phone.replace(country.dialCode, "");

      updatedPhoneNumbers[index] = {
        ...prevPhoneNumbers[index],
        countryCode: country.dialCode?.includes("+")
          ? country.dialCode
          : "+" + country.dialCode,
        phoneNumber: strippedPhoneNumber,
      };

      return updatedPhoneNumbers;
    });
  };

  const handleRemovePhoneNumber = (index: any) => {
    setPhoneNumbers((prevPhoneNumbers) => {
      const updatedPhoneNumbers = [...prevPhoneNumbers];
      updatedPhoneNumbers.splice(index, 1);
      formik.setFieldValue("phoneNumbers", updatedPhoneNumbers);
      return updatedPhoneNumbers;
    });
  };

  const handleAddPhoneNumber = () => {
    const newPhoneNumberEntry = {
      id: Math.random(),
      countryCode: "+971",
      phoneNumber: "",
    };
    setPhoneNumbers([...phoneNumbers, newPhoneNumberEntry]);
  };

  const [value1, setValue1] = React.useState(0);
  const handleChange1 = (event: React.SyntheticEvent, newValue: number) => {
    setValue1(newValue);
  };

  const getActiveStep = async () => {
    const activeStep = await getFromStorage(STORAGE_KEYS.activeProfileTab);
    if (activeStep) {
      setValue1(Number(activeStep));
    }
  };

  useEffect(() => {
    getActiveStep();
    const storedWalletAddress = getFromStorage(STORAGE_KEYS.WalletAddress);
    setWalletAddress(storedWalletAddress);
  }, []);

  return (
    <>
      <div className="custom_tabs pt_0">
        <div className="custom_tabs1">
          <Tabs
            value={value1}
            onChange={handleChange1}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="basic tabs example"
            className="custom_tabs_links"
          >
            <Tab label="General" {...a11yProps(0)} />
            <Tab label="Manage Social Media" {...a11yProps(1)} />
            <Tab label="Change Password" {...a11yProps(2)} />
            <Tab label="My Activities" {...a11yProps(3)} />
            <Tab label="My Wallet" {...a11yProps(4)} />
          </Tabs>
          <CustomTabPanel value={value1} index={0}>
            <div className="head_flex">
              <h3 className="mb">General</h3>
            </div>
            <form onSubmit={formik.handleSubmit} action="" className="form">
              <div className="gap_p">
                <div className="w_67">
                  <div className="form_control">
                    <label>Name</label>
                    <TextField
                      hiddenLabel
                      placeholder="Name"
                      fullWidth
                      type="text"
                      variant="outlined"
                      name="name"
                      className="text_field"
                      id="name"
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isString(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.name}
                    />
                    {Boolean(formik.touched.name && formik.errors.name) ? (
                      <span className="helper-text">
                        <h6 className="err_msg">
                          {(formik.touched.name && formik.errors.name) as any}
                        </h6>
                      </span>
                    ) : undefined}
                  </div>
                  <div className="form_control">
                    <label>Email Address</label>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      placeholder="Email"
                      disabled
                      fullWidth
                      type="email"
                      variant="outlined"
                      name="email"
                      id="email"
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else {
                          formik.handleChange(val);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                    />
                    {Boolean(formik.touched.email && formik.errors.email) ? (
                      <span className="helper-text">
                        <h6 className="err_msg">
                          {(formik.touched.email && formik.errors.email) as any}
                        </h6>
                      </span>
                    ) : undefined}
                  </div>
                  <div className="form_control">
                    <label>Date of Birth</label>
                    <TextField
                      hiddenLabel
                      placeholder="Date of Birth"
                      fullWidth
                      type="date"
                      variant="outlined"
                      className="text_field"
                      name="dob"
                      id="dob"
                      value={formik.values.dob}
                      onChange={formik.handleChange}
                      // helperText={
                      //   formik.touched.dob && formik.errors.dob
                      // }
                    />
                    {Boolean(formik.touched.dob && formik.errors.dob) ? (
                      <span className="helper-text">
                        <h6 className="err_msg">
                          {(formik.touched.dob && formik.errors.dob) as any}
                        </h6>
                      </span>
                    ) : undefined}
                  </div>
                  <div className="form_control">
                    {phoneNumbers?.map((entry, index) => (
                          <div key={entry.id} className="form_control">
                            <label>
                              Contact Number{" "}
                              {phoneNumbers?.length > 1 ? index + 1 : ""}
                            </label>
                            <div className="p_relative">
                              <PhoneInput
                                value={entry.countryCode + entry.phoneNumber}
                                country={entry.countryCode}
                                placeholder="Contact Number"
                                enableSearch={true}
                                inputStyle={{ width: "100%" }}
                                onChange={(phone, country) =>
                                  handleChangePhone(phone, country, index)
                                }
                                onBlur={formik.handleBlur}
                              />
                            </div>
                          {error &&
                            !entry?.phoneNumber?.length &&
                            phoneNumbers?.length < 2 ? (
                              <span className="helper-text">
                                <h6 className="err_msg">
                                  Phone number is required
                                </h6>
                              </span>
                            ) : undefined}
                            {error &&
                      entry?.phoneNumber?.length &&
                      entry?.phoneNumber?.length < 7 ? (
                        <span className="helper-text">
                          <h6 className="err_msg">
                            Phone number must have at least 7 digits.
                          </h6>
                        </span>
                      ) : undefined}
                            {phoneNumbers?.length > 1 &&
                            entry?.phoneNumber?.length ? (
                              <div className="text_end">
                                <button
                                  type="button"
                                  className="btn_link sm"
                                  onClick={() => handleRemovePhoneNumber(index)}
                                >
                                  REMOVE
                                </button>
                              </div>
                            ) : undefined}
                          </div>
                        ))
                     }
                    <div className="text_end">
                      <a
                        // href="javascript:void(0)"
                        className="btn_link sm"
                        onClick={handleAddPhoneNumber}
                      >
                        ADD ANOTHER CONTACT NUMBER
                      </a>
                    </div>
                  </div>
                  <div className="form_control">
                    {addresses?.length
                      ? addresses?.map((entry, index) => (
                          <div key={entry.id} className="form_control">
                            <label>
                              Address {addresses?.length > 1 ? index + 1 : ""}
                            </label>
                            <div className="p_relative">
                              <TextField
                                hiddenLabel
                                placeholder="Address"
                                fullWidth
                                type="text"
                                variant="outlined"
                                name={`addresses[${index}].address`}
                                className="text_field"
                                onChange={(val) =>
                                  handleChangeAddress(val.target.value, index)
                                }
                                onBlur={formik.handleBlur}
                                value={entry.address}
                              />
                            </div>
                            {addresses?.length > 1 && entry?.address && (
                              <div className="text_end">
                                <button
                                  type="button"
                                  className="btn_link sm"
                                  onClick={() => handleRemoveAddress(index)}
                                >
                                  REMOVE
                                </button>
                              </div>
                            )}
                           {/*} {error &&
                            !entry.address?.length &&
                            addresses?.length < 2 ? (
                              <span className="helper-text">
                                <h6 className="err_msg">Address is required</h6>
                              </span>
                            ) : undefined}*/}
                          </div>
                        ))
                      : undefined}
                    <div className="text_end">
                      <a
                        // href="javascript:void(0)"
                        className="btn_link sm"
                        onClick={handleAddAddress}
                      >
                        ADD ANOTHER ADDRESS
                      </a>
                    </div>
                  </div>
                </div>
                <div className="w_33">
                  <div className="form_control">
                    <label>PROFILE PICTURE</label>
                    {formik.values.image ? (
                      <div className="upload_image style_2">
                        <div className="upload_image_holder">
                          <figure>
                            <CardMedia
                              component="img"
                              image={formik.values.image}
                              alt="photo"
                            />
                          </figure>
                          <CloseIcon
                            onClick={() => {
                              formik.setFieldValue("image", "");
                            }}
                          />
                        </div>
                      </div>
                    ) : (
                      <label
                        className="upload_image style_2"
                        htmlFor="icon-button-file"
                      >
                        <Input
                          sx={{ display: "none" }}
                          id="icon-button-file"
                          type="file"
                          inputProps={{
                            accept: ".png, .jpg, .jpeg, .svg",
                          }}
                          onChange={handleImageUpload}
                        />
                        <span className="upload_image_holder">
                          <figure>
                            <img
                              src="/images/placeholder_user_icon.png"
                              alt=""
                            />
                          </figure>
                          <EditIcon />
                        </span>
                      </label>
                    )}
                  </div>
                  {cmsData?.isWeb3Enabled ? (
                    <Button
                      onClick={() => setModalOpen(true)}
                      className="btn btn_white wallet_btn "
                    >
                      <img src="/images/wallet_icon.png" alt="Wallet Icon" />
                      {user?.walletAddress
                        ? `${user?.walletAddress?.slice(
                            0,
                            5
                          )}...${user?.walletAddress?.slice(-5)}`
                        : "Link Wallet"}
                    </Button>
                  ) : undefined}
                  <Modal
                    open={openModal}
                    onClose={handleModalClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={style} className="modal_body">
                      <CloseIcon onClick={handleModalClose} className="close" />
                      <MetamaskModalContent />
                    </Box>
                  </Modal>
                </div>
              </div>
              <div className="form_btn text_center">
                <Button
                  className="btn btn_primary"
                  color="primary"
                  size="large"
                  type="submit"
                  variant="contained"
                  onClick={() => setError(true)}
                >
                  Update
                </Button>
              </div>
            </form>
          </CustomTabPanel>
          <CustomTabPanel value={value1} index={1}>
            <>
              {socialMediaHandles === 1 ? (
                <ManageSocialMedia
                  steps={steps}
                  setSteps={setSteps}
                  setSocialMediaHandles={setSocialMediaHandles}
                  socialMedia={socialMedia}
                  setSocialMedia={setSocialMedia}
                  setSelectedMedia={setSelectedMedia}
                  getSocialMediaData={getSocialMediaData}
                />
              ) : (
                <AddSocialMedia
                  steps={steps}
                  setSteps={setSteps}
                  setSocialMediaHandles={setSocialMediaHandles}
                  getSocialMediaData={getSocialMediaData}
                  socialMediaHandles={socialMediaHandles}
                  selectedMedia={selectedMedia}
                />
              )}
            </>
          </CustomTabPanel>
          <CustomTabPanel value={value1} index={2}>
            <ChangePassword steps={steps} setSteps={setSteps} />
          </CustomTabPanel>
          <CustomTabPanel value={value1} index={3}>
            <MyActivities steps={steps} setSteps={setSteps} />;
          </CustomTabPanel>
          <CustomTabPanel value={value1} index={4}>
            <Wallet />
          </CustomTabPanel>
        </div>
      </div>
    </>
  );
};

export default Setting;
