import { AuthCommon } from "../../../../components";
import { useNavigate } from "react-router-dom";
import { Button, TextField } from "@mui/material";
import { usePostForgotPasswordMutation } from "../../../../services/auth";
import { useFormik } from "formik";
import * as Yup from "yup";
import { generateEncryptedKeyBody } from "../../../../utils/crypto";
import { CommonBody } from "../../../../types/General";
import { Loader, errorToast, successToast } from "../../../../helpers";

const ForgotPasswordContent = () => {
  const navigate = useNavigate();

  const [ForgotPasswordMutation, { isLoading }] =
    usePostForgotPasswordMutation();

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required("Email is required!")
        .matches(
          /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
          "Enter a valid email address!"
        ),
    }),
    onSubmit: async () => {
      formik.setSubmitting(true);
      let body = {
        key: formik.values.email,
      };

      const data = {
        email: formik.values.email,
      };
      // navigate("/otp", { state: data });

      // return;

      try {
        let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
        const response = await ForgotPasswordMutation(encryptedBody).unwrap();
        if (response?.statusCode === 200) {
          const data = {
            email: formik.values.email,
          };
          navigate("/otp", { state: data });
          successToast(response?.message);
        }
      } catch (error: any) {
        errorToast(error?.data?.message || "");
      }
    },
  });

  return (
    <>
      <Loader isLoad={isLoading} />
      <section className="auth_section">
        <div className="conta_iner">
          <div className="gap_p">
            <AuthCommon type="login" />
            <div className="right_side">
              <form
                action=""
                className="form hd_3"
                onSubmit={formik.handleSubmit}
              >
                <h2>Forgot Password</h2>
                <div className="form_control">
                  <label>Email address *</label>
                  <TextField
                    hiddenLabel
                    placeholder="Email Address"
                    fullWidth
                    name="email"
                    type="email"
                    variant="outlined"
                    id="email"
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else {
                        formik.handleChange(val);
                      }
                    }}
                    className="text_field"
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    helperText={formik.touched.email && formik.errors.email}
                  />
                </div>
                <div className="form_btn">
                  <Button
                    className="btn btn_primary"
                    color="primary"
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Send
                  </Button>
                </div>
                <div className="form_bottom">
                  <p>
                    Back to{" "}
                    <a
                      // href="javacript:void(0)"
                      onClick={() => navigate("/signin")}
                      style={{cursor:'pointer'}}
                    >
                      Sign In
                    </a>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ForgotPasswordContent;
