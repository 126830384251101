import { useNavigate } from "react-router-dom";

const CorporateSitesSection4 = () => {
    const navigate = useNavigate();

    return (
        <section className="Corp_sc4 u_spc">
            <div className="conta_iner">
                <div className="s_head text_center">
                    <h2>Benefits of the impact2earn® platform</h2>
                </div>
                <div className="iconBoxes v2">
                    <div className="gap_m hd_5">
                        <div className="iconBoxes_single v2">
                            <figure><img src="/images/human-like-chatbot.svg" alt="" /></figure>
                            <h3>Easy</h3>
                            <p>Intuitive chat-based user interface allows users to send messages and receive responses in a conversational manner, which feels natural and familiar. We also support various types of media, such as images, videos, audio files, and documents.</p>
                        </div>
                        <div className="iconBoxes_single v2">
                            <figure><img src="/images/human-like-chatbot.svg" alt="" /></figure>
                            <h3>Transparency</h3>
                            <p>Blockchain technology helps provide accountability and reduces fraud in the recycling industry. By tracking each stage of the recycling process on a blockchain, stakeholders can have a clear and accurate view of the journey of materials, from collection to processing to reuse.</p>
                        </div>
                        <div className="iconBoxes_single v2">
                            <figure><img src="/images/human-like-chatbot.svg" alt="" /></figure>
                            <h3>Liquidity</h3>
                            <p>E-waste contains a significant amount of valuable materials. Extraction of these materials from the ground is many times more carbon-intensive than processing. We provide liquidity in a less carbon-intensive way.</p>
                        </div>
                        <div className="iconBoxes_single v2">
                            <figure><img src="/images/human-like-chatbot.svg" alt="" /></figure>
                            <h3>Integration</h3>
                            <p>Our platform is integrated with certified green blockchains that use carbon-neutral resources and offers a faster and more secure means of exchange. These blockchains addresses the environmental concerns of traditional blockchains by using low-energy consumption and 100% clean energy.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default CorporateSitesSection4;
