import React, { useState } from "react";
import {
  STORAGE_KEYS,
  errorToast,
  getFromStorage,
  setToStorage,
  successToast,
} from "../helpers";
import { generateEncryptedKeyBody } from "../utils/crypto";
import { CommonBody } from "../types/General";
import { useEditProfileMutation } from "../services/auth";
import { metaMaskAddress, setCredentials } from "../reducers/authSlice";
import { useAppDispatch } from "../hooks/store";
import useAuth from "../hooks/useAuth";

const MetamaskModalContent = () => {
  const dispatch = useAppDispatch();
  const user = useAuth();

  const [updateProfile] = useEditProfileMutation();

  const [copied, setCopied] = useState(false);
  console.log(copied, "copy");

  const copyToClipboard = async (address: string) => {
    try {
      // Write text to clipboard
      await navigator.clipboard.writeText(address);
      successToast("Copied To clipboard");
      setCopied(true);
      // Reset copied state after a few seconds
      setTimeout(() => {
        setCopied(false);
      }, 3000);
    } catch (error) {
      console.error("Error copying to clipboard:", error);
    }
  };

  const addWalletAddress = async (address: string) => {
    let body = {
      walletAddress: address,
    };
    console.log(body);

    try {
      let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
      const response = await updateProfile(encryptedBody).unwrap();
      console.log(response);
      if (response?.statusCode === 200) {
        const token = getFromStorage(STORAGE_KEYS.token);
        successToast("Wallet Address saved successfully");

        dispatch(
          setCredentials({
            user: response?.data || null,
            token: token || null,
          })
        );
      } else {
        errorToast(response?.message || "");
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };

  const connectWallet = async () => {
    if (window.ethereum) {
      try {
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        if (accounts.length) {
          setToStorage(STORAGE_KEYS.WalletAddress, accounts[0]);
          addWalletAddress(accounts[0]);

          dispatch(
            metaMaskAddress({
              walletAddress: accounts[0],
            })
          );
        }
      } catch (err) {
        console.log(err, "kk");
      }
    } else {
      window.open("https://metamask.io/download/", "_blank");
    }
  };

  return (
    <div className="modal_content metaMask_box">
      <h2 className="modal_title">
        {user?.walletAddress ? "" : `Let's Get Started`}
      </h2>
      <figure>
        <img src="/images/metamask_icon.svg" alt="Icon" />
      </figure>
      <h3>{user?.walletAddress ? "Linked Address" : "Link Your Metamask"}</h3>
      {!user?.walletAddress && (
        <button className="btn btn_primary" onClick={connectWallet}>
          Link
        </button>
      )}
      {user?.walletAddress && (
        <div className="link_box">
          <p>
            <span>{user?.walletAddress}</span>
            <figure
              onClick={() => copyToClipboard(user?.walletAddress)}
              title="Copy"
            >
              <img src="/images/copy_icon.svg" alt="Icon" />
            </figure>
            {/* {copied && (
              <span style={{ color: "green" }}>Copied to clipboard!</span>
            )} */}
          </p>
          {/* <button className="btn btn_primary">Link wallet</button> */}
        </div>
      )}
    </div>
  );
};

export default MetamaskModalContent;
