/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState } from "react";
import { Dispatch, SetStateAction } from "react";
import { Button, TextField, InputAdornment, IconButton } from "@mui/material";
import { usePostChangePasswordMutation } from "../../../services/auth";
import * as Yup from "yup";
import { useFormik } from "formik";
import { generateEncryptedKeyBody } from "../../../utils/crypto";
import { CommonBody } from "../../../types/General";
import { Loader, errorToast, successToast } from "../../../helpers";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const ChangePassword = ({
  steps,
  setSteps,
}: {
  steps: number;
  setSteps: Dispatch<SetStateAction<number>>;
}) => {
  const [changePassword, { isLoading }] = usePostChangePasswordMutation();

  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
    const inputEl = document.getElementById("oldPassword");
    if (inputEl) {
      setTimeout(() => {
        if (inputEl instanceof HTMLInputElement) {
          inputEl.setSelectionRange(inputEl.value.length, inputEl.value.length);
        }
      }, 0);
    }
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  const handleClickShowPassword1 = () => {
    setShowPassword1(!showPassword1);
    const inputEl = document.getElementById("newPassword");
    if (inputEl) {
      setTimeout(() => {
        if (inputEl instanceof HTMLInputElement) {
          inputEl.setSelectionRange(inputEl.value.length, inputEl.value.length);
        }
      }, 0);
    }
  };
  const handleMouseDownPassword1 = (event: any) => {
    event.preventDefault();
  };

  const handleClickShowPassword2 = () => {
    setShowPassword2(!showPassword2);
    const inputEl = document.getElementById("passwordConfirmation");
    if (inputEl) {
      setTimeout(() => {
        if (inputEl instanceof HTMLInputElement) {
          inputEl.setSelectionRange(inputEl.value.length, inputEl.value.length);
        }
      }, 0);
    }
  };
  const handleMouseDownPassword2 = (event: any) => {
    event.preventDefault();
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      newPassword: "",
      passwordConfirmation: "",
      oldPassword: "",
    },
    validationSchema: Yup.object({
      oldPassword: Yup.string()
        .label("Old Password")
        .required("Old password is required."),

      newPassword: Yup.string()
        .label("new Password")
        .required("New password is required.")
        .min(8, "New password must be at least 8 characters")
        .notOneOf(
          [Yup.ref("oldPassword"), null],
          "New password cannot be same as old password."
        ),

      passwordConfirmation: Yup.string()
        .oneOf([Yup.ref("newPassword")], "Passwords must match.")
        .required("Confirm password is required.")
        .min(8, "Confirm password must be at least 8 characters")
    }),
    onSubmit: async (values: any, { resetForm }) => {
      formik.setSubmitting(true);

      let body = {
        oldPassword: formik.values.oldPassword,
        password: formik.values.newPassword,
      };

      try {
        let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
        const response = await changePassword(encryptedBody).unwrap();
        if (response?.statusCode === 200) {
          resetForm();
          successToast("Password changed successfully" || "");
        }
      } catch (error: any) {
        errorToast(error?.data?.message || "");
      }
      formik.setSubmitting(false);
    },
  });

  return (
    <>
      <Loader isLoad={isLoading} />

      <h3>Change Password</h3>
      <form onSubmit={formik.handleSubmit} action="" className="form">
        <div className="gap_p">
          <div className="w_50">
            <div className="form_control">
              <label>Current Password</label>
              <TextField
                hiddenLabel
                placeholder="Current Password"
                fullWidth
                type={showPassword ? "text" : "password"}
                variant="outlined"
                className="text_field"
                name="oldPassword"
                id="oldPassword"
                onChange={(val) => {
                  if (val.target.value === " " || val.target.value === ".") {
                  } else {
                    formik.handleChange(val);
                  }
                }}
                onBlur={formik.handleBlur}
                value={formik.values.oldPassword}
                // helperText={
                //   formik.touched.oldPassword && formik.errors.oldPassword
                // }
                InputProps={{
                  endAdornment: (
                    <InputAdornment className="eye_btn" position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {Boolean(
                formik.touched.oldPassword && formik.errors.oldPassword
              ) ? (
                <span className="helper-text">
                  <h6 className="err_msg">
                    {
                      (formik.touched.oldPassword &&
                        formik.errors.oldPassword) as any
                    }
                  </h6>
                </span>
              ) : undefined}
            </div>
            <div className="form_control">
              <label>New Password</label>
              <TextField
                hiddenLabel
                placeholder="New Password"
                fullWidth
                type={showPassword1 ? "text" : "password"}
                variant="outlined"
                className="text_field"
                name="newPassword"
                id="newPassword"
                onChange={(val) => {
                  if (val.target.value === " " || val.target.value === ".") {
                  } else {
                    formik.handleChange(val);
                  }
                }}
                onBlur={formik.handleBlur}
                value={formik.values.newPassword}
                // helperText={
                //   formik.touched.newPassword && formik.errors.newPassword
                // }
                InputProps={{
                  endAdornment: (
                    <InputAdornment className="eye_btn" position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword1}
                        onMouseDown={handleMouseDownPassword1}
                        edge="end"
                      >
                        {showPassword1 ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {Boolean(
                formik.touched.newPassword && formik.errors.newPassword
              ) ? (
                <span className="helper-text">
                  <h6 className="err_msg">
                    {
                      (formik.touched.newPassword &&
                        formik.errors.newPassword) as any
                    }
                  </h6>
                </span>
              ) : undefined}
            </div>
            <div className="form_control">
              <label>Confirm Password</label>
              <TextField
                hiddenLabel
                placeholder="Confirm Password"
                fullWidth
                type={showPassword2 ? "text" : "password"}
                variant="outlined"
                className="text_field"
                name="passwordConfirmation"
                id="passwordConfirmation"
                onChange={(val) => {
                  if (val.target.value === " " || val.target.value === ".") {
                  } else {
                    formik.handleChange(val);
                  }
                }}
                onBlur={formik.handleBlur}
                value={formik.values.passwordConfirmation}
                // helperText={
                //   formik.touched.passwordConfirmation &&
                //   formik.errors.passwordConfirmation
                // }
                InputProps={{
                  endAdornment: (
                    <InputAdornment className="eye_btn" position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword2}
                        onMouseDown={handleMouseDownPassword2}
                        edge="end"
                      >
                        {showPassword2 ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {Boolean(
                formik.touched.passwordConfirmation &&
                  formik.errors.passwordConfirmation
              ) ? (
                <span className="helper-text">
                  <h6 className="err_msg">
                    {
                      (formik.touched.passwordConfirmation &&
                        formik.errors.passwordConfirmation) as any
                    }
                  </h6>
                </span>
              ) : undefined}
            </div>
          </div>
        </div>
        <div className="form_btn">
          <Button
            className="btn btn_primary"
            color="primary"
            size="large"
            type="submit"
            variant="contained"
          >
            Update
          </Button>
        </div>
      </form>
    </>
  );
};

export default ChangePassword;
