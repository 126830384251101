/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
import { useNavigate } from "react-router-dom";
import TuneIcon from "@mui/icons-material/Tune";
import SearchBar from "../../../components/SearchBar";
import SitePagination from "../../../components/sitePagination";
import { Dispatch, Key, SetStateAction } from "react";
import { STORAGE_KEYS, setToStorage } from "../../../helpers";
import { isValidInput } from "../../../utils/validation";
import NoDataFound from "../../../components/noData";

const ExploreSubcategory_Subcategory = ({
  subCategories,
  page,
  setPage,
  totalPages,
  searchTerm,
  setDebouncedSearchTerm,
  setSearchTerm,
  loading,
}: {
  subCategories: any;
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  totalPages: number;
  searchTerm?: string;
  setDebouncedSearchTerm: Dispatch<SetStateAction<string>>;
  setSearchTerm: Dispatch<SetStateAction<string>>;
  loading: boolean;
}) => {
  const navigate = useNavigate();

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  return (
    <section className="ec_category_sc ub_spc">
      <div className="conta_iner">
        <div className="s_head f_width s_head_flex">
          <h1>Explore Categories</h1>
          <div className="right_s">
            <SearchBar
              searchTerm={searchTerm}
              setDebouncedSearchTerm={setDebouncedSearchTerm}
              value={searchTerm}
              onCross={() => {
                setSearchTerm("");
                setDebouncedSearchTerm("");
              }}
              onChange={(val: any) => {
                if (isValidInput(val.target.value)) {
                  setSearchTerm(val.target.value);
                }
              }}
            />
            {/* <button className="btn_rounded">
              <TuneIcon />
            </button> */}
          </div>
        </div>
        <div className="subcategory_listing">
          <div className="gap_m">
            {subCategories?.length
              ? subCategories?.map(
                  (item: {
                    image: string;
                    _id: Key | null | undefined;
                    name: string;
                    description: string;
                  }) => (
                    <div
                      key={item?._id}
                      className="subcategory_listing_single"
                      onClick={() => {
                        setToStorage(
                          STORAGE_KEYS.subCategory,
                          item ? JSON.stringify(item) : ""
                        );
                        navigate(`/explore-subcategory/detail/${item?._id}`);
                      }}
                    >
                      <figure>
                        <img
                          src={item?.image || "/images/subcat_attachment_1.jpg"}
                          alt="Image"
                        />
                      </figure>
                      <h3>{item?.name || ""}</h3>
                      <p>{item?.description?.slice(0, 25) || ""}</p>
                    </div>
                  )
                )
              : undefined}
          </div>

          {/* <SitePagination /> */}
          {subCategories?.length ? (
            <SitePagination
              module={subCategories}
              page={page}
              onPageChange={onPageChange}
              totalPages={totalPages}
              setPage={setPage}
            />
          ) : undefined}
        </div>
        {!loading && !subCategories?.length ? (
          <NoDataFound image={"/images/noData.png"} text="No Data Found" />
        ) : undefined}
      </div>
    </section>
  );
};

export default ExploreSubcategory_Subcategory;
