import { List, ListItemText, ListItemButton, ListItem } from "@mui/material";
import useAuth from "../../../hooks/useAuth";
import {
  STORAGE_KEYS,
  errorToast,
  getFromStorage,
  removeFromStorage,
  setToStorage,
} from "../../../helpers";
import { useAppSelector } from "../../../hooks/store";
import { getCms } from "../../../reducers/generalSlice";

export default function SideBar({
  setSteps,
  steps,
}: {
  setSteps: any;
  steps: number;
}) {
  const user = useAuth();
  const cmsData = useAppSelector(getCms);

  const listArr = [
    {
      id: 1,
      name: `My Passive Assets`,
      display: true,
    },
    // {
    //   id: 2,
    //   name: `My Database Assets`,
    //   display: true,
    // },
    {
      id: 3,
      name: `My Minted Assets`,
      display: cmsData?.isWeb3Enabled ? true : false,
    },
    // {
    //   id: 4,
    //   name: `Manage Social Media`,
    //   display: true,
    // },
    {
      id: 5,
      name: `Gifts Received`,
      display: cmsData?.isWeb3Enabled ? true : false,
    },
    {
      id: 6,
      name: `Asset On Bid`,
      display: cmsData?.isWeb3Enabled ? true : false,
    },
    {
      id: 7,
      name: `Claimed Coupons`,
      display: true,
    },
    // {
    //   id: 8,
    //   name: `My Wallet`,
    //   display: true,
    // },
    // {
    //   id: 9,
    //   name: `My Activities`,
    //   display: true,
    // },
    // {
    //   id: 10,
    //   name: `Change Password`,
    //   display: true,
    // },
    {
      id: 11,
      name: `Personal Detail`,
      display: true,
    },
  ];

  const handleClick = async (item: any) => {
    if (item?.id === 3) {
      const token = getFromStorage(STORAGE_KEYS.token);
      if (cmsData?.isWeb3Enabled) {
        if (window.ethereum) {
          console.log("Metamask is installed.");
          if (window.ethereum.isConnected()) {
            console.log("Metamask is connected.");
            try {
              const accounts = await window.ethereum.request({
                method: "eth_requestAccounts",
              });
              if (accounts.length) {
                console.log(accounts);
                if (token && accounts[0] !== user?.walletAddress) {
                  errorToast("Please connect to a valid wallet address");
                } else {
                  await setToStorage(STORAGE_KEYS.activeTab, item?.id);
                  setSteps(item.id);

                  if (window) {
                    window.scrollTo({ top: 0, behavior: "auto" });
                  }
                }
              }
            } catch (err) {
              console.log(err, "kk");
            }
          } else {
            errorToast(
              "You need to connect to the metamask to proceed further"
            );
            console.log("Metamask is not connected.");
          }
        } else {
          console.log("Metamask is not installed.");
        }
      }
    } else if (item?.display) {
      await setToStorage(STORAGE_KEYS.activeTab, item?.id);
      setSteps(item.id);
      if (item?.id === 11) {
        await removeFromStorage(STORAGE_KEYS.activeProfileTab);
      }

      if (window) {
        window.scrollTo({ top: 0, behavior: "auto" });
      }
    }
  };

  const drawer = (
    <>
      <List sx={{ flexGrow: 1 }} className="account_listing">
        {listArr.map((item) => (
          <ListItem key={item.id}>
            <ListItemButton
              selected={steps === item.id}
              onClick={() => handleClick(item)}
              className={item.display ? "clickEnable" : "clickDisable"}
            >
              <ListItemText primary={item.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </>
  );
  return (
    <aside className="account_sidebar">
      <div className="account_profile">
        <figure>
          <img
            src={
              user?.image ? user?.image : "/images/placeholder_user_icon.png"
            }
            alt=""
          />
        </figure>
        <div className="account_profile_detail">
          <h2>{user?.name || "UserName"}</h2>
          <p>{user?.email || ""}</p>
        </div>
      </div>
      {drawer}
    </aside>
  );
}
