/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { AuthCommon } from "../../../../components";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { generateEncryptedKeyBody } from "../../../../utils/crypto";
import { CommonBody } from "../../../../types/General";
import {
  Loader,
  STORAGE_KEYS,
  errorToast,
  setToStorage,
  successToast,
} from "../../../../helpers";
import { usePostSignupMutation } from "../../../../services/auth";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useState } from "react";
import { setCredentials } from "../../../../reducers/authSlice";
import { useAppDispatch } from "../../../../hooks/store";

const RegisterContent = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [signUpMethod, { isLoading }] = usePostSignupMutation();

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
    const inputEl = document.getElementById("password");
    if (inputEl) {
      setTimeout(() => {
        if (inputEl instanceof HTMLInputElement) {
          inputEl.setSelectionRange(inputEl.value.length, inputEl.value.length);
        }
      }, 0);
    }
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
    const inputEl = document.getElementById("confirmPass");
    if (inputEl) {
      setTimeout(() => {
        if (inputEl instanceof HTMLInputElement) {
          inputEl.setSelectionRange(inputEl.value.length, inputEl.value.length);
        }
      }, 0);
    }
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  const handleMouseDownPassword2 = (event: any) => {
    event.preventDefault();
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      acceptConditions: false,
      confirmPass: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required("Email is required!")
        .matches(
          /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
          "Enter a valid email address!"
        ),
      password: Yup.string()
        .required("Password is required!")
        .min(8, "Your password must be at least 8 characters"),
      confirmPass: Yup.string()
        .oneOf([Yup.ref("password")], "Passwords must match.")
        .required("Confirm password is required!")
        .min(8, "Confirm password must be at least 8 characters"),
    }),
    onSubmit: async () => {
      formik.setSubmitting(true);
      let body = {
        key: formik.values.email,
        password: formik.values.password,
      };
      // navigate("/profile-setup");

      if (!formik.values.acceptConditions) {
        errorToast("Please accept terms of Use to proceed further");
        return;
      }

      // return;

      try {
        let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
        const response = await signUpMethod(encryptedBody).unwrap();
        if (response?.statusCode === 200) {
          // navigate("/otp", { state: { ...body, auth: "signup" } });
          // successToast(
          //   "OTP has been successfully sent on the email, if not received check spam email"
          // );
          setToStorage(STORAGE_KEYS.token, response?.data?.token || "");
          dispatch(
            setCredentials({
              user: response?.data,
              token: response?.data?.token || "",
            })
          );

          navigate("/profile-setup", { replace: true });
        }
      } catch (error: any) {
        errorToast(error?.data?.message || "");
      }
    },
  });

  return (
    <>
      <Loader isLoad={isLoading} />
      <section className="auth_section">
        <div className="conta_iner">
          <div className="gap_p">
            <AuthCommon />
            <div className="right_side">
              <form
                action=""
                className="form hd_3"
                onSubmit={formik.handleSubmit}
              >
                <h2>Register</h2>
                <div className="form_control">
                  <label>Email address *</label>
                  <TextField
                    hiddenLabel
                    placeholder="Email Address"
                    fullWidth
                    name="email"
                    type="email"
                    variant="outlined"
                    id="email"
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else {
                        formik.handleChange(val);
                      }
                    }}
                    className="text_field"
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    helperText={formik.touched.email && formik.errors.email}
                  />
                </div>
                <div className="form_control">
                  <label>Password *</label>
                  <TextField
                    hiddenLabel
                    placeholder="Password"
                    className="text_field"
                    fullWidth
                    name="password"
                    variant="outlined"
                    id="password"
                    type={showPassword ? "text" : "password"}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.password}
                    helperText={
                      formik.touched.password && formik.errors.password
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment className="eye_btn" position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div className="form_control">
                  <label>Confirm Password *</label>
                  <TextField
                    hiddenLabel
                    placeholder="Confirm Password"
                    className="text_field"
                    fullWidth
                    name="confirmPass"
                    variant="outlined"
                    id="confirmPass"
                    type={showConfirmPassword ? "text" : "password"}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.confirmPass}
                    helperText={
                      formik.touched.confirmPass && formik.errors.confirmPass
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment className="eye_btn" position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowConfirmPassword}
                            onMouseDown={handleMouseDownPassword2}
                            edge="end"
                          >
                            {showConfirmPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div className="form_control form_control_content">
                  {/* <p>
                    <b>A password will be sent to your email address.</b>
                  </p> */}
                  <p>
                    Your personal data will be used to support your experience
                    throughout this website, to manage access to your account,
                    and for other purposes described in our privacy policy.
                  </p>
                </div>
                <div className="form_control">
                  <div className="remember_box">
                    <FormControlLabel
                      control={<Checkbox />}
                      checked={formik.values.acceptConditions}
                      label=""
                      name="acceptConditions"
                      onChange={formik.handleChange}
                    />
                    <div className="text_lab">
                      Accept{" "}
                      <a
                        onClick={() => navigate("/terms")}
                        // href="javascript:void(0)"
                        style={{cursor:'pointer'}}
                      >
                        Terms of Use
                      </a>
                    </div>
                  </div>
                </div>
                <div className="form_btn">
                  <Button
                    className="btn btn_primary"
                    color="primary"
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Register
                  </Button>
                </div>
                <div className="form_bottom">
                  <p>
                    Already have an account?{" "}
                    <a
                      // href="javacript:void(0)"
                      onClick={() => navigate("/signin")}
                      style={{cursor:'pointer'}}
                    >
                      Sign In
                    </a>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default RegisterContent;
