/* eslint-disable react/jsx-pascal-case */
import React from "react";
import Layout from "../../layout";
import BecomePartner_Form from "./features/form";

const BecomePartner = () => {
    return (
        <Layout>
            <main className="page_becomePartner">
                <BecomePartner_Form />
            </main>
        </Layout>
    );
};

export default BecomePartner;
