/* eslint-disable react/jsx-pascal-case */
import React from "react";
import Layout from "../../layout";
import BecomeVendor_Form from "./features/form";

const BecomeVendor = () => {
    return (
        <Layout>
            <main className="page_becomeVendor">
                <BecomeVendor_Form />
            </main>
        </Layout>
    );
};

export default BecomeVendor;
