/* eslint-disable react/jsx-pascal-case */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import Layout from "../../layout";
import { Button, TextField } from "@mui/material";

const SignupFree = () => {
  return (
    <Layout>
      <main className="page_signupFree">
        <section className="form_sc uh_spc">
          <div className="conta_iner">
            <div className="s_head text_center">
              <h1>Join the New Movement</h1>
              <h2 className="c_primary">Sign up For Free</h2>
            </div>
            <form action="" className="form">
              <div className="form_control">
                <label>NAME *</label>
                <TextField
                  hiddenLabel
                  placeholder="Enter Name"
                  fullWidth
                  name="name"
                  type="text"
                  variant="outlined"
                />
              </div>
              <div className="form_control">
                <label>Email *</label>
                <TextField
                  hiddenLabel
                  placeholder="Enter Email"
                  fullWidth
                  name="company"
                  type="email"
                  variant="outlined"
                />
              </div>
              <div className="form_control">
                <label>Other Contact Method *</label>
                <TextField
                  hiddenLabel
                  placeholder="Enter Contact"
                  fullWidth
                  name="contact"
                  type="number"
                  variant="outlined"
                />
              </div>
              <div className="form_btn">
                <Button
                  className="btn btn_primary"
                  color="primary"
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  Submit
                </Button>
              </div>
            </form>
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default SignupFree;
