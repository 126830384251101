import Layout from "../../../layout";
import RegisterContent from "./features/register";
import "../../auth/auth.scss";

const Register = () => {
  return (
    <Layout>
      <main>
        <RegisterContent />
      </main>
    </Layout>
  );
};

export default Register;
