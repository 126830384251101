/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
import { useNavigate } from "react-router-dom";
import { useLazyGetAllNewsQuery } from "../../../services/cms";
import { useEffect, useState } from "react";
import { Blog } from "../../../types/General";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import moment from "moment";

const HomeNews = () => {
  const navigate = useNavigate();

  const [getNews] = useLazyGetAllNewsQuery();

  const [news, setNews] = useState<Blog[]>([]);

  const getAllNews = async () => {
    try {
      const res = await getNews({ search: "", page: 1 }).unwrap();
      if (res?.statusCode === 200) {
        setNews(res?.data?.data || []);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllNews();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {news?.length ? (
        <section className="home_news_sc u_spc">
          <div className="conta_iner">
            <div className="s_head text_center">
              <h2>Recent Articles and News</h2>
            </div>

            <div className="news_listing">
              <div className="gap_m hd_6">
                {news?.length
                  ? news?.slice(0, 3)?.map((item) => (
                      <div
                        className="news_single"
                        onClick={() => navigate(`/news/detail/${item?._id}`)}
                      >
                        <figure>
                          <img src={item?.image} alt="Image" />
                        </figure>
                        <div className="news_info">
                          <span className="date">
                            <strong>
                              {moment(item?.createdAt).format("Do")}
                            </strong>{" "}
                            {moment(item?.createdAt).format("MMM, YY")}
                          </span>
                          <span className="views">
                            <RemoveRedEyeIcon /> {item?.viewCount || 0} Views
                          </span>
                        </div>
                        <h3>{item?.title || ""}</h3>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: item?.description || "",
                          }}
                        ></p>
                        {item?.description?.length >= 100 ? (
                          <a href="javascript:void(0)" className="btn_link">
                            Read more <KeyboardArrowRightIcon />
                          </a>
                        ) : null}
                      </div>
                    ))
                  : null}
              </div>
            </div>

            {news?.length ? (
              <div className="btn_flex">
                <button
                  onClick={() => navigate("/news")}
                  className="btn btn_lg btn_primary"
                >
                  View More
                </button>
              </div>
            ) : undefined}
          </div>
        </section>
      ) : undefined}
    </>
  );
};

export default HomeNews;
