/* eslint-disable jsx-a11y/anchor-is-valid */
import { useNavigate } from "react-router-dom";

const LearnHow_Video = () => {
    const navigate = useNavigate();

    return (
        <section className="learnHow_video ub_spc">
            <div className="conta_iner">
                <iframe width="1280" height="720" src="https://www.youtube.com/embed/EimyGci1_iw" title="BANTgo impact2earn: Give Waste. Get Rewards." frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                {/* <figure>
                    <img src="/images/process_video.jpg" alt="" />
                    <a href="javascript:void(0)">
                        <img src="/images/play_icon.svg" alt="" />
                    </a>
                </figure> */}
            </div>
        </section>
    );
};

export default LearnHow_Video;
