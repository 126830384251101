import { InputAdornment, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { Dispatch, SetStateAction, useEffect } from "react";
import ClearIcon from "@mui/icons-material/Clear";

type SearchProps = {
  value?: string;
  onChange?: any;
  onCross?: () => void;
  searchTerm: string | undefined;
  setDebouncedSearchTerm: Dispatch<SetStateAction<string>>;
};

const SearchBar = ({
  value,
  onChange,
  onCross,
  setDebouncedSearchTerm,
  searchTerm,
}: SearchProps) => {
  useEffect(() => {
    const delay = 1000;
    const timerId = setTimeout(() => {
      if (searchTerm) {
        setDebouncedSearchTerm(searchTerm);
      }
    }, delay);
    return () => {
      clearTimeout(timerId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  return (
    <div className="search_bar">
      <div className="form_control">
        <TextField
          value={value}
          onChange={onChange}
          hiddenLabel
          placeholder="Search here"
          className="txt_inpt"
          fullWidth
          variant="outlined"
          InputProps={{
            // startAdornment: (
            //   <InputAdornment position="end" className="search_icon">
            //     <SearchIcon />
            //   </InputAdornment>
            // ),
            endAdornment: (
              <InputAdornment position="end" className="search_icon">
                {value ? (
                  <ClearIcon
                    style={{ color: "red", cursor: "pointer" }}
                    onClick={onCross}
                  />
                ) : (
                  <SearchIcon />
                )}
              </InputAdornment>
            ),
          }}
        />
      </div>
    </div>
  );
};
export default SearchBar;
