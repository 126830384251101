import SearchBar from "../../../components/SearchBar";
import NewsListing from "../../../components/newsListing";
import SitePagination from "../../../components/sitePagination";
import { Blog } from "../../../types/General";
import NewsRecentListing from "./newsRecentList";
import { isValidInput } from "../../../utils/validation";
import { Dispatch, SetStateAction } from "react";
import { Loader } from "../../../helpers";

type props = {
  news: Blog[];
  searchTerm?: string;
  setDebouncedSearchTerm: Dispatch<SetStateAction<string>>;
  setSearchTerm: Dispatch<SetStateAction<string>>;
  debouncedSearchTerm?: string | undefined;
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  onPageChange: (newPage: number) => void;
  totalPages: number;
  loading: boolean;
};

const NewsGrid = ({
  news,
  setDebouncedSearchTerm,
  setSearchTerm,
  searchTerm,
  debouncedSearchTerm,
  page,
  setPage,
  onPageChange,
  totalPages,
  loading,
}: props) => {
  return (
    <section className="newsGrid_sc u_spc">
      <Loader loading={loading} />
      <div className="conta_iner">
        <div className="gap_p">
          <div className="left_s">
            <NewsListing search={debouncedSearchTerm} page={page} />
            {totalPages > 1 ? (
              <SitePagination
                module={news}
                page={page}
                onPageChange={onPageChange}
                totalPages={totalPages}
                setPage={setPage}
              />
            ) : undefined}
          </div>
          <div className="right_s">
            <aside className="news_aside">
              <div className="single">
                <SearchBar
                  searchTerm={searchTerm}
                  setDebouncedSearchTerm={setDebouncedSearchTerm}
                  value={searchTerm}
                  onCross={() => {
                    setSearchTerm("");
                    setDebouncedSearchTerm("");
                  }}
                  onChange={(val: any) => {
                    if (isValidInput(val.target.value)) {
                      setSearchTerm(val.target.value);
                    }
                  }}
                />
              </div>
              <div className="single">
                <NewsRecentListing news={news} />
              </div>
            </aside>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NewsGrid;
